<template>
  <!-- html head title, meta -->
  <HeadTitle :title="title"></HeadTitle>

  <div class="flex flex-col justify-between h-screen bg-gray-5">
    <div class="relative main">
      <Header></Header>
      <div class="container">
        <div class="flex items-center justify-between my-8">
          <PageHeader>
            <template #breadcrumb>
              <PageHeaderItem
                v-for="(crumb, index) in breadcrumbs"
                :key="index"
                :href="crumb.href"
                :skipArrow="index === 0"
              >
                {{ crumb.title }}</PageHeaderItem
              >
            </template>
          </PageHeader>
          <div>
            <FlashNotice></FlashNotice>
            <FlashAlert></FlashAlert>
          </div>
        </div>

        <slot></slot>
      </div>
    </div>

    <!-- <Footer></Footer> -->
  </div>
</template>

<script setup>
import { computed } from "vue";
import { usePage } from "@inertiajs/vue3";
import HeadTitle from "@layouts/commons/head_title.vue";
import Header from "@layouts/commons/header.vue";
import Footer from "@layouts/commons/footer.vue";

const title = computed(() => usePage().props.title);
const breadcrumbs = computed(() => usePage().props.breadcrumbs);
</script>
