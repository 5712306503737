<template>
  <div class="card">
    <DataTable :resources="products" :paginate="paginate">
      <template #actions>
        <Link href="/products/new" class="btn btn-sm btn-primary">
          <IconSvg icon="new" class="w-4 h-4"></IconSvg>
          {{ $t("helpers.new", { model: $t("activerecord.models.product") }) }}
        </Link>
      </template>
      <template #thead>
        <DataTableHead id="checkbox" :disableOrder="true" class="w-5">
          <label for="product-select-all" class="custom-checkbox">
            <input
              type="checkbox"
              id="product-select-all"
              v-model="selectAll"
            />
            <span class="checkmark checkmark-sm"></span>
          </label>
        </DataTableHead>
        <DataTableHead
          v-for="thead in theads"
          :id="thead.id"
          :text="$t(`activerecord.attributes.product.${thead.i18n_key}`)"
          :defaultOrderColumn="thead.defaultOrderColumn"
          :disableOrder="thead.disableOrder"
        >
        </DataTableHead>
      </template>
      <template #tbody-tr="{ resource }">
        <td>
          <span class="flex items-center gap-1">
            <label
              :for="`product-select-${resource.id}`"
              class="custom-checkbox"
            >
              <input
                type="checkbox"
                :id="`product-select-${resource.id}`"
                v-model="download_ids"
                :value="resource.id"
              />
              <span class="checkmark checkmark-sm"></span>
            </label>
          </span>
        </td>
        <td>
          <Link class="text-gray-60" :href="`/products/${resource.id}/edit`">{{
            resource.name
          }}</Link>
        </td>
        <td>{{ resource.supplier }}</td>
        <td>{{ resource.subsidiary }}</td>
        <td>{{ resource.owner_unit }}</td>
        <td>{{ resource.comment }}</td>
      </template>
      <template #footer-actions>
        <button
          :disabled="download_ids.length === 0"
          type="button"
          class="btn btn-sm btn-primary"
          @click="downloadProducts"
        >
          <IconSvg icon="download" class="w-4 h-4 fill-white"></IconSvg>
          {{ $t("helpers.download", { text: $t("product.sub_nav.info") }) }}
        </button>
      </template>
    </DataTable>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import { router } from "@inertiajs/vue3";
const props = defineProps({
  products: {
    type: Array,
    required: true,
  },
  paginate: {
    type: Object,
    required: true,
  },
  authenticity_token: {
    type: String,
    required: true,
  },
});

const theads = [
  {
    id: "name",
    i18n_key: "name",
    defaultOrderColumn: true,
    disableOrder: false,
  },
  {
    id: "supplier",
    i18n_key: "supplier",
    disableOrder: true,
  },
  {
    id: "subsidiary",
    i18n_key: "subsidiary",
    disableOrder: true,
  },
  {
    id: "owner_unit",
    i18n_key: "owner_unit",
    disableOrder: true,
  },
  {
    id: "comment",
    i18n_key: "comment",
    disableOrder: true,
  },
];

const download_ids = ref([]);
const downloadProducts = () => {
  const form = document.createElement("form");
  form.method = "POST";
  form.action = "/products/download.csv";

  const csrfToken = document.createElement("input");
  csrfToken.type = "hidden";
  csrfToken.name = "authenticity_token";
  csrfToken.value = props.authenticity_token;

  document.body.appendChild(form);
  form.appendChild(csrfToken);

  download_ids.value.forEach((id) => {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = "ids[]";
    input.value = id;
    form.appendChild(input);
  });
  form.submit();
  form.remove();
};

const selectAll = ref(false);

onMounted(() => {
  if (download_ids.value.length === props.products.length) {
    selectAll.value = true;
  } else if (download_ids.value.length > 0) {
    selectAll.value = false;
  } else {
    selectAll.value = false;
  }

  watch(selectAll, (newVal) => {
    if (newVal) {
      download_ids.value = props.products.map((product) => product.id);
    } else {
      download_ids.value = [];
    }
  });

  watch(
    () => download_ids.value,
    (newVal) => {
      const checkedIds = newVal;
      const element = document.querySelector("#product-select-all");

      if (checkedIds.length === 0) {
        element.indeterminate = false;
        selectAll.value = false;
      } else if (checkedIds.length === props.products.length) {
        element.indeterminate = false;
        selectAll.value = true;
      } else {
        element.indeterminate = true;
      }
    }
  );
});
</script>
