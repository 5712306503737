<template>
  <div
    id="authentication-content"
    role="tabpanel"
    aria-labelledby="authentication-tab"
  >
    <div class="flex items-center gap-5 py-3">
      <span>{{ $t("activerecord.attributes.user.enable") }}</span>
      <label for="user_enable" class="custom-radio">
        <input
          type="radio"
          id="user_enable"
          v-model="form.user.enable"
          value="true"
        />
        {{ $t("user.enable.true") }}
        <span class="checkmark"></span>
      </label>
      <label for="user_disable" class="custom-radio">
        <input
          type="radio"
          id="user_disable"
          v-model="form.user.enable"
          value="false"
        />
        {{ $t("user.enable.false") }}
        <span class="checkmark"></span>
      </label>
    </div>

    <div class="flex items-center gap-5 py-3">
      <p class="flex items-center gap-5">
        <span>
          {{ $t("activerecord.attributes.user.current_sign_in_ip") }}
        </span>
        <span> {{ props.current_sign_in_ip }} </span>
      </p>
      <p class="flex items-center gap-5">
        <span>
          {{ $t("activerecord.attributes.user.current_sign_in_at") }}
        </span>
        <span> {{ props.current_sign_in_at }} </span>
      </p>
    </div>
    <div class="flex items-center gap-5 py-3">
      <button
        class="btn btn-sm btn-primary"
        type="button"
        :disabled="!props.has_confirmed"
        @click="handleResetPassword"
      >
        {{ $t("user.reset_password.button") }}
      </button>
    </div>
  </div>
</template>
<script setup>
import { useForm, router } from "@inertiajs/vue3";
import { ref, watch, onMounted } from "vue";

const props = defineProps({
  user: {
    type: Object,
    required: true,
  },
  user_id: {
    type: String,
  },
  current_sign_in_at: {
    type: String,
  },
  current_sign_in_ip: {
    type: String,
  },
  has_confirmed: {
    type: Boolean,
  },
});
const form = useForm({
  user: {
    enable: props.user.enable,
  },
});

const handleResetPassword = () => {
  router.visit(`/users/${props.user_id}/reset_password`, {
    method: "post",
    preserveState: true,
  });
};

const emit = defineEmits(["submit"]);

onMounted(() => {
  watch(form.user, () => {
    emit("submit", form.user);
  });
});
</script>
