<template>
  <nav
    class="py-0 nav nav-common"
    :class="$attrs.class"
  >
    <Link
      class="nav-item"
      v-for="sub_nav in subNavList"
      as="button"
      :key="sub_nav.href"
      :href="sub_nav.href"
      :disabled="!sub_nav.enable"
      :class="{ 'active': isActive(sub_nav.href) }"
    >
      <span
        class="nav-link"
      >
        {{ sub_nav.text }}
      </span>
    </Link>
  </nav>
</template>
<script setup>
import { usePage } from "@inertiajs/vue3";
const props = defineProps({
  subNavList: Array,
});

const isActive = (href) => {
  return href === usePage().url;
};
</script>
