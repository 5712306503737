<template>
  <ConfirmModal
    :modal-cancel-text="$t('helpers.cancel')"
    :modal-submit-text="$t('helpers.confirm')"
    modal-submit-class="btn btn-sm btn-primary"
    :modal-title="$t('helpers.list_setting')"
    :modal-subtitle="$t('cloud_account.list_setting.select_max_9')"
    modal-size="lg"
    @submit="updateListSetting"
    class="btn btn-sm btn-primary btn-outline"
  >
    <template #sub-title>
      <p class="text-sm text-gray-80">
        {{ $t("cloud_account.list_setting.select_max_9") }}
      </p>
    </template>
    <template #button>
      <IconSvg icon="menu" class="w-4 h-4 fill-primary-dark"></IconSvg>
      {{ $t("helpers.list_setting") }}
    </template>

    <div class="grid grid-cols-4 gap-10">
      <div class="flex flex-col gap-2">
      <h5 class="text-sm font-bold text-gray-80">帳號基本資料</h5>

      <label for="list_cloud_account_account_id" class="custom-checkbox">
        <input
          type="checkbox"
          id="list_cloud_account_account_id"
          v-model="selected_columns"
          value="account_id"
          disabled
        />
        {{ $t("cloud_account.index.account_id") }}
        <span class="checkmark"></span>
      </label>

      <label for="list_cloud_account_main_category" class="custom-checkbox">
        <input
          type="checkbox"
          id="list_cloud_account_main_category"
          class="selected-column"
          v-model="selected_columns"
          value="main_category"
        />
        {{ $t("cloud_account.index.main_category") }}
        <span class="checkmark"></span>
      </label>

      <label for="list_cloud_account_summary_name" class="custom-checkbox">
        <input
          type="checkbox"
          id="list_cloud_account_summary_name"
          class="selected-column"
          v-model="selected_columns"
          value="summary_name"
        />
        {{ $t("cloud_account.index.summary_name") }}
        <span class="checkmark"></span>
      </label>

      <label for="list_cloud_account_name" class="custom-checkbox">
        <input
          type="checkbox"
          id="list_cloud_account_name"
          class="selected-column"
          v-model="selected_columns"
          value="name"
        />
        {{ $t("cloud_account.index.name") }}
        <span class="checkmark"></span>
      </label>

      <label for="list_cloud_account_email_name" class="custom-checkbox">
        <input
          type="checkbox"
          id="list_cloud_account_email_name"
          class="selected-column"
          v-model="selected_columns"
          value="email_name"
        />
        {{
          $t("cloud_account.index.email_name")
        }}
        <span class="checkmark"></span>
      </label>

      <label for="list_cloud_account_status" class="custom-checkbox">
        <input
          type="checkbox"
          id="list_cloud_account_status"
          class="selected-column"
          v-model="selected_columns"
          value="status"
        />
        {{ $t("cloud_account.index.status") }}
        <span class="checkmark"></span>
      </label>

      <label for="list_cloud_account_testing_duration" class="custom-checkbox">
        <input
          type="checkbox"
          id="list_cloud_account_testing_duration"
          class="selected-column"
          v-model="selected_columns"
          value="testing_duration"
        />
        {{ $t("cloud_account.index.testing_duration") }}
        <span class="checkmark"></span>
      </label>

      <label for="list_cloud_account_start_at" class="custom-checkbox">
        <input
          type="checkbox"
          id="list_cloud_account_start_at"
          class="selected-column"
          v-model="selected_columns"
          value="start_at"
        />
        {{ $t("cloud_account.index.start_at") }}
        <span class="checkmark"></span>
      </label>

      <label for="list_cloud_account_end_at" class="custom-checkbox">
        <input
          type="checkbox"
          id="list_cloud_account_end_at"
          class="selected-column"
          v-model="selected_columns"
          value="end_at"
        />
        {{ $t("cloud_account.index.end_at") }}
        <span class="checkmark"></span>
      </label>

      <label for="list_cloud_account_amount_currency" class="custom-checkbox">
        <input
          type="checkbox"
          id="list_cloud_account_amount_currency"
          class="selected-column"
          v-model="selected_columns"
          value="amount_currency"
        />
        {{ $t("cloud_account.index.amount_currency") }}
        <span class="checkmark"></span>
      </label>

      <label for="list_cloud_account_amount" class="custom-checkbox">
        <input
          type="checkbox"
          id="list_cloud_account_amount"
          class="selected-column"
          v-model="selected_columns"
          value="amount"
        />
        {{ $t("cloud_account.index.amount") }}
        <span class="checkmark"></span>
      </label>
    </div>
    <div class="flex flex-col gap-2">
      <h5 class="text-sm font-bold text-gray-80">客戶基本資料</h5>
    </div>
    <div class="flex flex-col gap-2">
      <h5 class="text-sm font-bold text-gray-80">客戶商業條件</h5>
    </div>
    <div class="flex flex-col gap-2">
      <h5 class="text-sm font-bold text-gray-80">果核成本條件</h5>

      <label for="list_cloud_account_hide_origin_credit" class="custom-checkbox">
        <input
          type="checkbox"
          id="list_cloud_account_hide_origin_credit"
          class="selected-column"
          v-model="selected_columns"
          value="hide_origin_credit"
        />
        {{ $t("cloud_account.index.hide_origin_credit") }}
          <span class="checkmark"></span>
        </label>
      </div>
    </div>
  </ConfirmModal>
</template>

<script setup>
import { ref, computed, onMounted, watch } from "vue";
import { router } from "@inertiajs/vue3";

const props = defineProps({
  list_setting: Array,
  authenticity_token: String,
});

const selected_columns = ref(props.list_setting);
const updateListSetting = () => {
  router.put(`/cloud_accounts/list_setting`, {
    list_setting: selected_columns.value
  });
};

const toggleColumnDisabled = (selected_columns) => {
  if (selected_columns.length >= 10) {
    document.querySelectorAll(".selected-column:not(:checked)").forEach((column) => {
      column.disabled = true;
    });
  } else {
    document.querySelectorAll(".selected-column:not(:checked)").forEach((column) => {
      column.disabled = false;
    });
  }
}

onMounted(() => {
  toggleColumnDisabled(selected_columns.value)
  watch(selected_columns, () => {
    toggleColumnDisabled(selected_columns.value)
  });
});
</script>
