<template>
  <!-- Start coding here -->
  <div class="relative datatable" :class="{ striped: stripedClass }">
    <!-- head -->
    <div class="flex items-center justify-between px-4 py-3">
      <div class="flex items-center justify-between w-max">
        <slot name="actions"></slot>
      </div>
      <div v-if="!disableSearch">
        <div class="relative">
          <label for="simple-search" class="relative block">
            <IconSvg
              icon="search"
              class="w-4 h-4 fill-gray-80 with-input"
            ></IconSvg>

            <input
              v-model="search"
              @input="onSearch"
              type="text"
              id="simple-search"
              class="input with-icon"
              :placeholder="searchPlaceholder || $t('helpers.search')"
            />
          </label>
        </div>
      </div>
    </div>

    <!-- content -->
    <div class="overflow-x-auto">
      <template v-if="!onlyList">
        <table class="w-full text-sm text-left text-gray-60">
          <thead>
            <tr>
              <slot name="thead"></slot>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(resource, index) in resources" :key="index">
              <slot name="tbody-tr" :resource="resource"></slot>
            </tr>
          </tbody>
        </table>
      </template>
      <template v-else>
        <div class="bg-gray-100 border-y border-gray-300 px-5 py-2 space-y-4 max-h-[800px] overflow-y-auto">
          <div v-for="(resource, index) in resources" :key="index">
            <slot name="item" :resource="resource"></slot>
          </div>
        </div>
      </template>
    </div>

    <div class="flex items-center justify-between w-full px-4 pt-3">
      <div
        class="flex items-center justify-start gap-10"
        v-if="paginate"
      >
        <div class="flex items-center justify-between gap-1">
          <multiselect
            v-model="displayLength"
            :options="per_page_options"
            :show-labels="false"
            :searchable="false"
            :allow-empty="false"
            :max-height="120"
            class="multiselect-sm min-w-max"
            @select="onDisplayLength"
          >
            <template #singleLabel="props">
              <span class="option__desc">
                <span class="option__title">{{ props.option }}</span>
              </span>
            </template>
            <template #caret>
              <IconSvg
                icon="arrow_down"
                class="absolute w-4 h-4 -translate-y-1/2 top-1/2 right-2 fill-gray-80"
              ></IconSvg>
            </template>
          </multiselect>
          <span
            class="flex items-center gap-1 text-sm"
            v-html="$t('table.items_per_page')"
          ></span>
        </div>
        <!-- paginate -->
        <div  class="flex items-center justify-between">
          <slot name="paginate">
            <Paginate :paginate="paginate"></Paginate>
          </slot>
        </div>
      </div>
      <slot name="footer-actions"></slot>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { router } from "@inertiajs/vue3";
import Paginate from "@components/datatable/paginate.vue";
import { useHelper } from "./helpers";

const props = defineProps({
  resources: { type: Array, required: true, default: [] },
  disableSearch: { type: Boolean, default: false },
  searchPlaceholder: { type: String },
  paginate: { type: Object },
  searchEndpoint: { type: String },
  stripedClass: { type: Boolean, default: true },
  onlyList: { type: Boolean, default: false },
});

// params
const helper = useHelper();
const search = ref(helper.urlParams.value.get("search") || undefined);
const displayLength = ref(helper.urlParams.value.get("pre") || 10);

const onRefreshData = helper.debounce(() => {
  const url = props.searchEndpoint || window.location.pathname;

  router.get(url, filterParams.value, { preserveState: true });
}, 200);

const onSearch = () => {
  onRefreshData();
};
const onDisplayLength = () => {
  onRefreshData();
};

// computed
const filterParams = computed(() => {
  const params = Object.fromEntries(helper.urlParams.value);

  // search
  if (search.value) {
    params.search = search.value;
  } else {
    params.search = undefined;
  }

  // displayLength
  if (Number(params.pre) !== Number(displayLength.value)) {
    params.page = undefined;
  }
  if (Number(displayLength.value) === 10) {
    params.pre = undefined;
  } else {
    params.pre = displayLength.value;
  }

  return params;
});

const per_page_options = [10, 25, 50, 100];
</script>
