<template>
  <div
    id="connect_way-content"
    role="tabpanel"
    aria-labelledby="connect_way-tab"
    class="hidden"
  >
    <dl class="grid grid-cols-9 gap-5 my-5">
      <dt class="flex items-center col-span-1">
        <label class="ml-3" for="connect_way">{{
          $t("activerecord.attributes.cloud_account.connect_way")
        }}</label>
      </dt>
      <dd class="flex items-center justify-between col-span-8 gap-5 w-max">
        <multiselect
          v-model="form.connect_way"
          :options="connectWayOptions"
          :show-labels="false"
          :searchable="false"
          :allow-empty="false"
          :max-height="120"
        >
          <template #singleLabel="props">
            {{ $t(`cloud_account.connect_way.${props.option}`) }}
          </template>
          <template #option="props">
            {{ $t(`cloud_account.connect_way.${props.option}`) }}
          </template>
          <template #caret>
            <IconSvg
              icon="arrow_down"
              class="absolute w-4 h-4 -translate-y-1/2 top-1/2 right-2 fill-gray-80"
            ></IconSvg>
          </template>
        </multiselect>
      </dd>
    </dl>
    <ConnectWayApi
      v-if="form.connect_way === 'api'"
      :connect_credentials="form.connect_credentials"
      :updated_at="props.cloud_account_connector.updated_at"
      @submit="modifyConnectorAttributes"
    />
  </div>
</template>
<script setup>
import { useForm } from "@inertiajs/vue3";
import { ref, onMounted, computed } from "vue";
import ConnectWayApi from "./connect_way_api.vue";
const props = defineProps({
  cloud_account_connector: Object,
});
const form = useForm({
  connect_way: props.cloud_account_connector.connect_way,
  connect_credentials: props.cloud_account_connector.credentials,
});


// modify if more than one connect_way
const connectWayOptions = ref([props.cloud_account_connector.connect_way]);

const emit = defineEmits(["submit"]);

const modifyConnectorAttributes = (credentials) => {
  form.connect_credentials = credentials;

  emit("submit", {
    cloud_account_connector_attributes: {
      id: props.cloud_account_connector.id,
      connect_way: form.connect_way,
      credentials: form.connect_credentials,
    },
  });
};
</script>
