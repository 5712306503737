<template>
  <DataTable :resources="all_user_groups" :striped-class="false">
    <template #actions>
      <h3 class="text-xl font-bold text-gray-80">
        {{ $t('user.user_group.new') }}
      </h3>
    </template>
    <template #thead>
      <DataTableHead
        id="checkbox"
        :defaultOrderColumn="false"
        :disableOrder="true"
      >
        <label for="group-select-all" class="custom-checkbox">
          <input type="checkbox" id="group-select-all" v-model="selectAll" />
          {{ $t("activerecord.attributes.user_group.name") }}
          <span class="checkmark checkmark-sm"></span>
        </label>
      </DataTableHead>
      <DataTableHead
        v-for="thead in theads"
        :id="thead.id"
        :text="$t(`activerecord.attributes.user_group.${thead.i18n_key}`)"
        :defaultOrderColumn="thead.defaultOrderColumn"
        :disableOrder="thead.disableOrder"
      >
      </DataTableHead>
    </template>
    <template #tbody-tr="{ resource }">
      <td>
        <label :for="`group-select-${resource.id}`" class="custom-checkbox">
          <input
            type="checkbox"
            :id="`group-select-${resource.id}`"
            disabled
            v-if="user_group_ids.includes(resource.id)"
            checked
          />
          <input
            type="checkbox"
            :id="`group-select-${resource.id}`"
            v-model="form.user_group_ids"
            :value="resource.id"
            v-else
          />
          {{ resource.name }}
          <span class="checkmark checkmark-sm"></span>
        </label>
      </td>
      <td>{{ resource.description }}</td>
      <td>{{ resource.permissions }}</td>
    </template>
  </DataTable>
</template>
<script setup>
import { useForm } from "@inertiajs/vue3";
import { ref, watch, onMounted } from "vue";

const props = defineProps({
  user_group_ids: {
    type: Array,
    required: true,
  },
  all_user_groups: {
    type: Array,
    required: true,
  },
});

const theads = [
  {
    id: "description",
    i18n_key: "description",
    disableOrder: true,
  },
  {
    id: "permissions",
    i18n_key: "permissions",
    disableOrder: true,
  },
];

const form = useForm({
  user_group_ids: [],
});

const selectAll = ref(false);
const selectableGroups = props.all_user_groups.filter(
  (group) => !props.user_group_ids.includes(group.id)
);

const emit = defineEmits(["submit"]);

onMounted(() => {
  if (form.user_group_ids.length === selectableGroups.length) {
    selectAll.value = true;
  } else if (form.user_group_ids.length > 0) {
    selectAll.value = false;
  } else {
    selectAll.value = false;
  }

  watch(selectAll, (newVal) => {
    if (newVal) {
      form.user_group_ids = selectableGroups.map((group) => group.id);
    } else {
      form.user_group_ids = [];
    }
  });

  watch(
    () => form.user_group_ids,
    (newVal) => {
      const checkedIds = newVal;
      const element = document.querySelector("#group-select-all");

      if (checkedIds.length === 0) {
        element.indeterminate = false;
        selectAll.value = false;
      } else if (checkedIds.length === selectableGroups.length) {
        element.indeterminate = false;
        selectAll.value = true;
      } else {
        element.indeterminate = true;
      }

      emit("submit", checkedIds);
    }
  );
});
</script>
