<template>
  <div class="form-actions">
    <div>
      <ConfirmModal
        :button-text="$t('helpers.delete')"
        :modal-cancel-text="$t('delete_confirm.back')"
        :modal-submit-text="$t('helpers.delete')"
        :modal-title="$t('delete_confirm.title')"
        :url="deleteUrl"
        @submit="deleteAction"
        class="px-10 btn btn-sm btn-warning"
        v-if="deleteUrl"
      >
        <p>
          {{
            $t("delete_confirm.message", {
              target: deleteTarget,
              from: deleteFrom,
            })
          }}
        </p>
      </ConfirmModal>
    </div>
    <div class="flex gap-5">
      <ConfirmModal
        :button-text="$t('helpers.back')"
        :modal-cancel-text="$t('changes_not_saved.back')"
        :modal-submit-text="$t('changes_not_saved.confirm')"
        :modal-title="$t('changes_not_saved.title')"
        @submit="backAction"
        class="px-10 btn btn-sm btn-primary btn-outline"
        :class="{
          hidden: !hasModified,
        }"
      >
        <p>
          {{ $t("changes_not_saved.message") }}
        </p>
      </ConfirmModal>
      <Link
        :href="backUrl"
        class="px-10 btn btn-sm btn-primary btn-outline"
        :class="{
          hidden: hasModified,
        }"
      >
        {{ $t("helpers.back") }}
      </Link>
      <button
        class="px-10 btn btn-sm btn-primary"
        :disabled="!canSubmit || processing"
      >
        {{ submitText || $t("helpers.submit") }}
      </button>
    </div>
  </div>
</template>

<script setup>
import { useForm, usePage, router } from "@inertiajs/vue3";

const props = defineProps({
  canSubmit: Boolean,
  backUrl: String,
  deleteUrl: String,
  hasModified: Boolean,
  deleteTarget: String,
  deleteFrom: String,
  submitText: String,
  processing: Boolean,
});

const deleteAction = () => {
  router.visit(props.deleteUrl, {
    method: "delete",
  });
};

const backAction = () => {
  router.visit(props.backUrl);
};
</script>
