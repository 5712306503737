<template>
  <div class="card">
    <div class="card-body">
      <SubNav :sub-nav-list="tabList"></SubNav>
    </div>
    <DataTable :resources="contracts" :paginate="paginate">
      <template #actions>
        <div class="px-1">
          <Link
            :href="`/products/${product_id}/contracts/new`"
            class="btn btn-sm btn-primary"
          >
            <IconSvg icon="new" class="w-4 h-4"></IconSvg>
            {{
              $t("helpers.new", {
                model: $t("activerecord.models.supplier/contract"),
              })
            }}
          </Link>
        </div>
      </template>
      <template #thead>
        <DataTableHead
          v-for="thead in theads"
          :id="thead.id"
          :text="$t(`supplier/contract.index.${thead.i18n_key}`)"
          :defaultOrderColumn="thead.defaultOrderColumn"
          :disableOrder="thead.disableOrder"
          class="px-5"
        >
        </DataTableHead>
      </template>
      <template #tbody-tr="{ resource }">
        <td class="px-1">
          <Link
            class="text-gray-60"
            :href="`/products/${product_id}/contracts/${resource.id}/edit`"
            >{{ resource.name }}</Link
          >
        </td>
        <td>
          {{
            $t(
              `supplier/contract.status.${resource.status}`
            )
          }}
        </td>
        <td></td>
        <td></td>
        <td>{{ resource.comment }}</td>
      </template>
    </DataTable>
  </div>
</template>
<script setup>
import { usePage } from "@inertiajs/vue3";

const props = defineProps({
  product_id: String,
  contracts: Array,
  paginate: Object,
});

const theads = [
  {
    id: "name",
    i18n_key: "name",
    disableOrder: true,
  },
  {
    id: "status",
    i18n_key: "status",
    disableOrder: true,
  },
  {
    id: "pricing_item",
    i18n_key: "pricing_item",
    disableOrder: true,
  },
  {
    id: "cloud_account",
    i18n_key: "cloud_account",
    disableOrder: true,
  },
  {
    id: "comment",
    i18n_key: "comment",
    disableOrder: true,
  },
];

const tabList = usePage().props.parent_menu_lists;
</script>
