<template>
  <div
    id="group-content"
    role="tabpanel"
    aria-labelledby="group-tab"
    class="hidden"
  >
    <div class="-mx-4 -mt-3 min-h-[300px]">
      <DataTable :resources="userGroupList">
        <template #actions>
          <ConfirmModal
            :button-text="$t('user.user_group.new')"
            :modal-cancel-text="$t('helpers.back')"
            :modal-submit-text="$t('user.user_group.submit')"
            modal-size="xl"
            modalSubmitClass="btn btn-sm btn-primary"
            @submit="addUserGroup"
            class="btn btn-sm btn-primary"
          >
            <SelectGroup
              :user_group_ids="form.user.user_group_ids"
              :all_user_groups="all_user_groups"
              @submit="addTempUserGroup"
              :key="resetSelectGroup"
            />
          </ConfirmModal>
        </template>
        <template #thead>
          <DataTableHead
            v-for="thead in theads"
            :id="thead.id"
            :text="$t(`activerecord.attributes.user_group.${thead.i18n_key}`)"
            :defaultOrderColumn="thead.defaultOrderColumn"
            :disableOrder="thead.disableOrder"
          >
          </DataTableHead>
          <DataTableHead
            id="actions"
            :text="$t('helpers.actions')"
            :disableOrder="true"
          >
          </DataTableHead>
        </template>
        <template #tbody-tr="{ resource }">
          <td>{{ resource.name }}</td>
          <td>{{ resource.description }}</td>
          <td>{{ resource.created_at || $t("helpers.not_added") }}</td>
          <td>
            <span
              class="underline cursor-pointer text-warning"
              @click="removeUserGroup(resource.id)"
            >
              {{ $t("helpers.remove") }}
            </span>
          </td>
        </template>
      </DataTable>
    </div>
  </div>
</template>

<script setup>
import { useForm } from "@inertiajs/vue3";
import { ref, watch, onMounted } from "vue";
import SelectGroup from "./select_group.vue";

const theads = [
  {
    id: "name",
    i18n_key: "name",
    defaultOrderColumn: true,
  },
  {
    id: "description",
    i18n_key: "description",
    disableOrder: true,
  },
  {
    id: "created_at",
    i18n_key: "created_at",
    disableOrder: true,
  },
];

const props = defineProps({
  user: {
    type: Object,
    required: true,
  },
  user_groups: {
    type: Array,
    required: true,
  },
  all_user_groups: {
    type: Array,
    required: true,
  },
});

const form = useForm({
  user: {
    user_group_ids: props.user.user_group_ids,
  },
});

const resetSelectGroup = ref(0);
const userGroupList = ref([...props.user_groups]);

const tempUserGroupIds = ref([]);
const addTempUserGroup = (user_group_ids) => {
  tempUserGroupIds.value = user_group_ids;
};

const addUserGroup = () => {
  const newUserGroup = props.all_user_groups.filter((user_group) => {
    return tempUserGroupIds.value.includes(user_group.id);
  });
  // add temp user group ids to user group list
  userGroupList.value = [...newUserGroup, ...userGroupList.value];
  // remove duplicate user group
  userGroupList.value = userGroupList.value.filter(
    (user_group, index, self) =>
      index === self.findIndex((t) => t.id === user_group.id)
  );
};

const removeUserGroup = (user_group_id) => {
  userGroupList.value = userGroupList.value.filter((user_group) => {
    return user_group.id !== user_group_id;
  });
  tempUserGroupIds.value = tempUserGroupIds.value.filter((id) => {
    return id !== user_group_id;
  });
  resetSelectGroup.value += 1;
};

const emit = defineEmits(["submit"]);
onMounted(() => {
  watch(userGroupList, () => {
    form.user.user_group_ids = userGroupList.value.map(
      (user_group) => user_group.id
    );

    emit("submit", form.user);
  });
});
</script>
