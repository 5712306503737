<template>
  <div class="relative">
    <IconSvg icon="date" class="w-4 h-4 fill-black with-input"></IconSvg>
    <flat-pickr
      v-model="date"
      :config="fullConfig"
      class="input with-icon calendar datepicker"
      :placeholder="$t('helpers.select')"
      :class="$attrs.class"
      :disabled="disabled"
    />
  </div>
</template>
<script setup>
import { ref, onMounted, computed, watch } from "vue";
import { usePage } from "@inertiajs/vue3";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { MandarinTraditional } from "flatpickr/dist/l10n/zh-tw.js";
import { Mandarin } from "flatpickr/dist/l10n/zh.js";
import { english } from "flatpickr/dist/l10n/default.js";

const props = defineProps({
  current_date: String,
  config: Object,
  disabled: Boolean || false,
});

const date = ref(null);

const locale = computed(() => {
  switch (usePage().props.locale) {
    case "zh-TW":
      return MandarinTraditional;
      break;
    case "zh-CN":
      return Mandarin;
      break;
    default:
      return english;
      break;
  }
});

const fullConfig = ref({
  locale: locale.value,
  defaultDate: props.current_date,
  dateFormat: "Y-m-d",
  ...props.config,
});

const emit = defineEmits(["submit"]);
const disabled = computed(() => {
  return props.disabled;
});

onMounted(() => {
  watch(disabled, () => {
    if (disabled.value) {
      date.value = null;
    }
  });
  watch(date, () => {
    emit("submit", date.value);
  });
});
</script>
