<template>
  <div class="space-5">
    <dl class="grid grid-cols-8 gap-5 my-5">
      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="product_main_category">{{
          $t("activerecord.attributes.product.main_category")
        }}</label>
      </dt>
      <dd class="flex items-center justify-between col-span-7 gap-5 w-max">
        <div>
          <multiselect
            v-model="form.product.main_category"
            :options="props.options_for_select.main_categories"
            :show-labels="false"
            :searchable="false"
            :allow-empty="false"
            :max-height="120"
          >
            <template #singleLabel="props">
              {{ $t(`product.main_category.${props.option}`) }}
            </template>
            <template #option="props">
              {{ $t(`product.main_category.${props.option}`) }}
            </template>
            <template #caret>
              <IconSvg
                icon="arrow_down"
                class="absolute w-4 h-4 -translate-y-1/2 top-1/2 right-2 fill-gray-80"
              ></IconSvg>
            </template>
          </multiselect>
        </div>
        <IconSvg icon="arrow_right" class="w-6 h-6 fill-black"></IconSvg>
        <label for="product_summary_name" class="ml-3 required">{{
          $t("activerecord.attributes.product.summary_name")
        }}</label>

        <div class="flex items-center gap-1">
          <label for="product_summary_name"
            >{{ $t(`product.main_category.${form.product.main_category}`) }} -
          </label>
          <span>
            <input
              type="text"
              class="input"
              id="product_summary_name"
              v-model="form.product.summary_name"
              :class="{
                error: !form.product.summary_name,
              }"
            />
          </span>
        </div>

        <IconSvg icon="arrow_right" class="w-6 h-6 fill-black"></IconSvg>

        <label for="product_name" class="ml-3 required">{{
          $t("activerecord.attributes.product.name")
        }}</label>

        <div class="flex items-center gap-1">
          <label for="product_name"
            >{{ $t(`product.main_category.${form.product.main_category}`) }} -
            {{ form.product.summary_name }} -
          </label>
          (
          <span>
            <input
              type="text"
              class="input"
              id="product_name"
              v-model="form.product.name"
              :class="{
                error: !form.product.name,
              }"
            />
          </span>
          )
        </div>
      </dd>

      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="product_category">{{
          $t("activerecord.attributes.product.category")
        }}</label>
      </dt>
      <dd class="flex items-center justify-between col-span-7 gap-5 w-max">
        <label for="category_idc" class="custom-radio">
          <input
            type="radio"
            id="category_idc"
            v-model="form.product.category"
            value="idc"
          />
          {{ $t("product.category.idc") }}
          <span class="checkmark"></span>
        </label>
      </dd>

      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="product_subsidiary">{{
          $t("activerecord.attributes.product.subsidiary")
        }}</label>
      </dt>
      <dd class="flex items-center justify-between col-span-7 gap-5 w-max">
        <label for="subsidiary_dgc_tw" class="custom-radio">
          <input
            type="radio"
            id="subsidiary_dgc_tw"
            v-model="form.product.subsidiary"
            value="dgc_tw"
          />
          {{ $t("product.subsidiary.dgc_tw") }}
          <span class="checkmark"></span>
        </label>
        <label for="subsidiary_dgc_hk" class="custom-radio">
          <input
            type="radio"
            id="subsidiary_dgc_hk"
            v-model="form.product.subsidiary"
            value="dgc_hk"
          />
          {{ $t("product.subsidiary.dgc_hk") }}
          <span class="checkmark"></span>
        </label>
      </dd>

      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="product_supplier">{{
          $t("activerecord.attributes.product.supplier")
        }}</label>
      </dt>
      <dd class="flex items-center justify-between col-span-7 gap-5 w-max">
        <input
          type="text"
          class="input"
          id="product_supplier"
          v-model="form.product.supplier"
          :class="{
            error: !form.product.supplier,
          }"
        />
      </dd>

      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="product_owner">{{
          $t("activerecord.attributes.product.owner")
        }}</label>
      </dt>
      <dd class="flex items-center justify-between col-span-7 gap-5 w-max">
        <div class="flex items-center">
          <multiselect
            v-model="selectedOwner"
            :options="props.options_for_select.owners"
            :show-labels="false"
            :searchable="false"
            :allow-empty="false"
            :max-height="120"
            class="min-w-[300px]"
            :placeholder="$t('helpers.select')"
            @select="form.product.owner_id = $event.id"
          >
            <template #singleLabel="props">
              {{ `${props.option.email} (${props.option.name})` }}
            </template>
            <template #option="props">
              {{ `${props.option.email} (${props.option.name})` }}
            </template>
            <template #caret>
              <IconSvg
                icon="arrow_down"
                class="absolute w-4 h-4 -translate-y-1/2 top-1/2 right-2 fill-gray-80"
              ></IconSvg>
            </template>
          </multiselect>
        </div>
        <label for="product_owner_unit" class="ml-3 required">
          {{ $t("activerecord.attributes.product.owner_unit") }}</label
        >

        <label for="product_owner_unit" class="custom-radio">
          <input
            type="radio"
            id="product_owner_unit"
            v-model="form.product.owner_unit"
            value="product_service"
          />
          {{ $t(`product.owner_unit.${form.product.owner_unit}`) }}
          <span class="checkmark"></span>
        </label>
      </dd>
    </dl>
    <hr class="border-gray-40" />

    <dl class="grid grid-cols-6 gap-5 my-5">
      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="product_primary_contact_email">{{
          $t("activerecord.attributes.product.primary_contact_email")
        }}</label>
      </dt>
      <dd class="flex items-center justify-between col-span-2 gap-5 w-max">
        <input
          class="input"
          id="product_primary_contact_email"
          type="email"
          v-model="form.product.primary_contact.email"
        />
      </dd>
      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="product_billing_contact_email">{{
          $t("activerecord.attributes.product.billing_contact_email")
        }}</label>
      </dt>
      <dd class="flex items-center justify-between col-span-2 gap-5 w-max">
        <input
          class="input"
          id="product_billing_contact_email"
          type="email"
          v-model="form.product.billing_contact.email"
        />
      </dd>
      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="product_primary_contact_name">{{
          $t("activerecord.attributes.product.primary_contact_name")
        }}</label>
      </dt>
      <dd class="flex items-center justify-between col-span-2 gap-5 w-max">
        <input
          class="input"
          id="product_primary_contact_name"
          type="text"
          v-model="form.product.primary_contact.name"
        />
      </dd>
      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="product_billing_contact_name">{{
          $t("activerecord.attributes.product.billing_contact_name")
        }}</label>
      </dt>
      <dd class="flex items-center justify-between col-span-2 gap-5 w-max">
        <input
          class="input"
          id="product_billing_contact_name"
          type="text"
          v-model="form.product.billing_contact.name"
        />
      </dd>
      <dt class="flex items-center col-span-1">
        <label class="ml-3" for="product_primary_contact_phone">{{
          $t("activerecord.attributes.product.primary_contact_phone")
        }}</label>
      </dt>
      <dd class="flex items-center justify-between col-span-2 gap-5 w-max">
        <input
          class="input"
          id="product_primary_contact_phone"
          type="text"
          v-model="form.product.primary_contact.phone"
        />
      </dd>
      <dt class="flex items-center col-span-1">
        <label class="ml-3" for="product_billing_contact_phone">{{
          $t("activerecord.attributes.product.billing_contact_phone")
        }}</label>
      </dt>
      <dd class="flex items-center justify-between col-span-2 gap-5 w-max">
        <input
          class="input"
          id="product_billing_contact_phone"
          type="text"
          v-model="form.product.billing_contact.phone"
        />
      </dd>

      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="product_origin_currency">{{
          $t("activerecord.attributes.product.origin_currency")
        }}</label>
      </dt>
      <dd class="flex items-center justify-between col-span-5 gap-5">
        <div>
          <multiselect
            v-model="form.product.origin_currency"
            :options="props.options_for_select.currencies"
            :show-labels="false"
            :searchable="false"
            :allow-empty="false"
            :max-height="120"
            :placeholder="$t('helpers.select')"
            class="min-w-[100px]"
          >
            <template #singleLabel="props">
              {{ $t(`product.origin_currency.${props.option}`) }}
            </template>
            <template #option="props">
              {{ $t(`product.origin_currency.${props.option}`) }}
            </template>
            <template #caret>
              <IconSvg
                icon="arrow_down"
                class="absolute w-4 h-4 -translate-y-1/2 top-1/2 right-2 fill-gray-80"
              ></IconSvg>
            </template>
          </multiselect>
        </div>
      </dd>
      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="product_trade_currency">{{
          $t("activerecord.attributes.product.trade_currency")
        }}</label>
      </dt>
      <dd class="flex items-center col-span-5 gap-5">
        <div>
          <multiselect
            v-model="form.product.trade_currency"
            :options="props.options_for_select.currencies"
            :show-labels="false"
            :searchable="false"
            :allow-empty="false"
            :max-height="120"
            :placeholder="$t('helpers.select')"
            class="min-w-[100px]"
          >
            <template #singleLabel="props">
              {{ $t(`product.trade_currency.${props.option}`) }}
            </template>
            <template #option="props">
              {{ $t(`product.trade_currency.${props.option}`) }}
            </template>
            <template #caret>
              <IconSvg
                icon="arrow_down"
                class="absolute w-4 h-4 -translate-y-1/2 top-1/2 right-2 fill-gray-80"
              ></IconSvg>
            </template>
          </multiselect>
        </div>
        <label class="ml-3 required" for="product_payment_term">{{
          $t("activerecord.attributes.product.payment_term")
        }}</label>
        <div class="flex items-center gap-1">
          <multiselect
            v-model="form.product.payment_term"
            :options="props.options_for_select.payment_terms"
            :show-labels="false"
            :searchable="false"
            :allow-empty="false"
            :max-height="120"
            :placeholder="$t('helpers.select')"
            class="min-w-[100px]"
          >
            <template #singleLabel="props">
              {{ $t(`product.payment_term.${props.option}`) }}
            </template>
            <template #option="props">
              {{ $t(`product.payment_term.${props.option}`) }}
            </template>
            <template #caret>
              <IconSvg
                icon="arrow_down"
                class="absolute w-4 h-4 -translate-y-1/2 top-1/2 right-2 fill-gray-80"
              ></IconSvg>
            </template>
          </multiselect>
          <input
            class="input"
            id="product_payment_term_other"
            type="text"
            v-model="form.product.payment_term_other"
            v-if="form.product.payment_term === 'other'"
          />
        </div>
        <label class="ml-3 required" for="product_exchange_rate">{{
          $t("activerecord.attributes.product.exchange_rate")
        }}</label>
        <div class="flex items-center gap-1">
          <multiselect
            v-model="form.product.exchange_rate"
            :options="props.options_for_select.exchange_rates"
            :show-labels="false"
            :searchable="false"
            :allow-empty="false"
            :max-height="120"
            :placeholder="$t('helpers.select')"
          >
            <template #singleLabel="props">
              {{ $t(`product.exchange_rate.${props.option}`) }}
            </template>
            <template #option="props">
              {{ $t(`product.exchange_rate.${props.option}`) }}
            </template>
            <template #caret>
              <IconSvg
                icon="arrow_down"
                class="absolute w-4 h-4 -translate-y-1/2 top-1/2 right-2 fill-gray-80"
              ></IconSvg>
            </template>
          </multiselect>
          <input
            class="input"
            id="product_exchange_rate_fix"
            type="number"
            min="0"
            v-model="form.product.exchange_rate_fix"
            v-if="form.product.exchange_rate === 'fix_rate'"
          />
        </div>
      </dd>
      <dt class="flex items-center col-span-1">
        <label class="ml-3" for="product_comment">{{
          $t("activerecord.attributes.product.comment")
        }}</label>
      </dt>
      <dd class="flex items-center justify-between w-full col-span-5 gap-5">
        <input
          class="input"
          id="product_comment"
          v-model="form.product.comment"
        />
      </dd>
    </dl>
  </div>
</template>
<script setup>
import { useForm, router } from "@inertiajs/vue3";
import { ref, watch, onMounted } from "vue";

const props = defineProps({
  product: {
    type: Object,
    required: true,
  },
  options_for_select: Object,
});
const form = useForm({
  product: {
    main_category: props.product.main_category,
    category: props.product.category,
    subsidiary: props.product.subsidiary,
    owner_unit: props.product.owner_unit,
    currency: props.product.currency,
    payment_term: props.product.payment_term,
    payment_term_other: props.product.payment_term_other,
    exchange_rate: props.product.exchange_rate,
    exchange_rate_fix: props.product.exchange_rate_fix,
    supplier: props.product.supplier,
    name: props.product.name,
    summary_name: props.product.summary_name,
    billing_contact: props.product.billing_contact,
    primary_contact: props.product.primary_contact,
    origin_currency: props.product.origin_currency,
    trade_currency: props.product.trade_currency,
    owner_id: props.product.owner_id,
    comment: props.product.comment,
  },
});

const emit = defineEmits(["submit"]);
const selectedOwner = ref(props.options_for_select.owners.find(owner => owner.id === props.product.owner_id));
onMounted(() => {
  watch(form.product, () => {
    emit("submit", form.product);
  });
});
</script>
