<template>
  <div class="pb-0 card">
    <div class="card-body">
      <div class="flex items-center justify-between">
        <SubNav :sub-nav-list="tabList"></SubNav>
        <div class="flex items-center gap-2">
          <h5 class="font-bold">
            {{ $t("activerecord.attributes.product.name") }}：{{ product.name }}
          </h5>
          <IconSvg icon="arrow_right" class="w-4 h-4 fill-black"></IconSvg>
          <h5 class="font-bold">
            {{ $t("activerecord.attributes.supplier/contract.name") }}：{{ contract.name }}
          </h5>
        </div>
      </div>

      <div class="grid grid-cols-2 gap-5">
        <div class="flex items-center justify-between gap-2">
          <label for="pricing_item_name" class="ml-3 required">
            {{ $t("activerecord.attributes.supplier/pricing_item.name") }}
          </label>
          <input v-model="form.pricing_item.name" type="text" class="flex-1 input" id="pricing_item_name" />
        </div>

        <div class="flex items-center justify-between gap-2">
          <label for="pricing_item_account_number" class="ml-3 required">
            {{ $t("activerecord.attributes.supplier/pricing_item.hook_account") }}
          </label>
          <input v-model="form.pricing_item.account_number" type="text" class="flex-1 input" id="pricing_item_account_number" />
        </div>
      </div>

      <div class="grid grid-cols-12 gap-5">
        <div class="flex items-center justify-start col-span-2 gap-2">
          <label for="pricing_item_currency" class="ml-3 required">
            {{ $t("activerecord.attributes.supplier/pricing_item.currency") }}
          </label>
          <select v-model="form.pricing_item.currency" class="flex-1 input max-w-[100px] !min-w-[100px]" id="pricing_item_currency">
            <option value="TWD">TWD</option>
            <option value="USD">USD</option>
          </select>
        </div>

        <div class="flex items-center justify-start col-span-4 gap-2">
          <label for="pricing_item_exchange_rate" class="ml-3 required">
            {{ $t("activerecord.attributes.supplier/pricing_item.exchange_rate") }}
          </label>
          <select v-model="form.pricing_item.exchange_rate" class="flex-1 input" id="pricing_item_exchange_rate">
            <option value="TWD">集團合併營收匯率 1USD=1TWD</option>
          </select>
          <input v-model="form.pricing_item.exchange_rate_percentage" type="text" class="flex-1 input max-w-[100px] !min-w-[100px]" id="pricing_item_exchange_rate_percentage" readonly disabled />%
        </div>
      </div>

      <div class="grid grid-cols-12 gap-5">
        <div class="flex items-center justify-start col-span-3 gap-2">
          <label for="pricing_item_upstream_technical_support" class="ml-3 required">
            {{ $t('activerecord.attributes.supplier/pricing_item.upstream_support') }}
          </label>
          <input v-model="form.pricing_item.upstream_technical_support" type="text" class="input flex-1 max-w-[150px] !min-w-[150px]" id="pricing_item_account_number" />
        </div>

        <div class="flex items-center justify-between col-span-4 gap-2">
          <label for="pricing_item_email" class="ml-3 required">
            {{ $t('activerecord.attributes.supplier/pricing_item.upstream_support_way') }}
          </label>
          <label for="pricing_item_email" class="custom-checkbox">
            <input type="checkbox"
                   id="pricing_item_email" />
            {{ $t('supplier/pricing_item.upstream_support_way.email') }}
            <span class="checkmark"></span>
          </label>
          <input type="text" id="pricing_item_email" class="flex-1 input" />
        </div>

        <div class="flex items-center justify-start col-span-5 gap-2">
          <label for="pricing_item_group" class="custom-checkbox">
            <input type="checkbox"
                   id="pricing_item_group" />
            {{ $t('supplier/pricing_item.upstream_support_way.group') }}
            <span class="checkmark"></span>
          </label>
          <select v-model="form.pricing_item.communication_software" class="flex-1 input max-w-[150px] !min-w-[150px]" id="pricing_item_group">
            <option value="skype">Skype</option>
            <option value="whatsapp">WhatsApp</option>
            <option value="telegram">Telegram</option>
            <option value="line">Line</option>
            <option value="dingding">釘釘</option>
            <option value="wechat">微信</option>
          </select>
          <input v-model="form.pricing_item.group_name" type="text" id="pricing_item_group_name" class="input flex-1 max-w-[200px] !min-w-[200px]" />
        </div>
      </div>

      <div class="grid grid-cols-12 gap-5">
        <div class="flex items-center justify-start col-span-3 gap-5">
          <label for="pricing_item_service_fee" class="ml-3 required">
            {{ $t("activerecord.attributes.supplier/pricing_item.service_fee_percent") }}
          </label>

          <div class="flex items-center gap-1">
            <span v-t="'supplier/pricing_item.form.service_fee_percent_prefix'"></span>
            <input v-model="form.pricing_item.service_fee" type="text" class="input flex-1 max-w-[150px] !min-w-[150px]" id="pricing_item_service_fee" />
            <span>%</span>
          </div>
        </div>

        <div class="flex items-center justify-start col-span-3 gap-5">
          <label for="pricing_item_general_discount" class="ml-3 required">
            {{ $t("activerecord.attributes.supplier/pricing_item.common_discount_percent") }}
          </label>
          <div class="flex items-center gap-1">
            <span>-</span>
            <input v-model="form.pricing_item.general_discount" type="text" class="input flex-1 max-w-[150px] !min-w-[150px]" id="pricing_item_general_discount" />
            <span>%</span>
          </div>
        </div>

        <ConfirmModal
          :button-text="`${$t('supplier/pricing_item.form.exclude_discount.button')}(all)`"
          :modal-cancel-text="$t('helpers.back')"
          modalSubmitClass="btn btn-sm btn-primary"
          class="btn btn-primary min-w-[150px] !p-3">

          <template #modelHeader>
            <div class="flex items-center justify-between px-4 py-3">
              <h3 class="text-xl font-bold text-gray-80">
                {{ $t('supplier/pricing_item.form.exclude_discount.modal.title') }}
              </h3>

              <div class="relative">
                <label for="simple-search" class="relative block">
                  <IconSvg
                    icon="search"
                    class="w-4 h-4 fill-gray-80 with-input"
                  ></IconSvg>

                  <input
                    v-model="search"
                    type="text"
                    id="simple-search"
                    class="input with-icon"
                    :placeholder="$t('helpers.search')"
                  />
                </label>
              </div>
            </div>
          </template>
          <div class="grid grid-cols-3 gap-2">
            <span v-for="(item, index) in excludeDiscountsFilter" :key="index">
              <label :for="`exclude_item_${index}`" class="custom-checkbox">
                <input type="checkbox" :id="`exclude_item_${index}`" v-model="form.pricing_item.exclude_discount_ids" :value="item.id" />
                {{item.name}}
                <span class="checkmark"></span>
              </label>
            </span>
          </div>
        </ConfirmModal>
      </div>
    </div>

    <DataTable :resources="specialDiscounts" onlyList>
      <template #actions>
        <ConfirmModal
          :modal-cancel-text="$t('helpers.back')"
          :modal-title="$t('supplier/pricing_item.special_discount.title')"
          modalSubmitClass="btn btn-sm btn-primary"
          modalSize="lg"
          @submit="addSpecialDiscount"
          class="btn btn-primary min-w-[150px] !p-3">
          <template #button>
            <IconSvg icon="new" class="w-4 h-4"></IconSvg>
            {{ $t('supplier/pricing_item.special_discount.new_button') }}
          </template>

          <template #default>
            <div class="divide-y *:p-3 last:pb-0">
              <div class="grid grid-cols-12 gap-5">
                <div class="self-center col-span-2">
                  <label for="pricing_item_name" class="ml-3 required">
                    {{ $t("supplier/pricing_item.special_discount.form.name") }}
                  </label>
                </div>
                <div class="col-span-10">
                  <input v-model="specialDiscount.name" type="text" class="flex-1 input" id="pricing_item_name" />
                </div>
              </div>

              <div class="grid grid-cols-12 gap-5">
                <div class="self-center col-span-2 ms-3">
                  {{ $t("supplier/pricing_item.special_discount.form.condition") }}
                </div>
                <div class="col-span-10 space-y-2">
                  <div v-for="(condition, index) in specialDiscount.conditions" :key="index">
                    <div class="flex items-center justify-between gap-2">
                      <select v-model="condition.condition_type" class="input w-[120px]">
                        <option :value="null">{{ $t('helpers.select') }}</option>
                        <option value="product_service">{{ $t('supplier/pricing_item.special_discount.form.condition_types.product_service') }}</option>
                        <option value="sub_product_service">{{ $t('supplier/pricing_item.special_discount.form.condition_types.sub_product_service') }}</option>
                        <option value="custom">{{ $t('supplier/pricing_item.special_discount.form.condition_types.custom') }}</option>
                      </select>

                      <input v-if="condition.condition_type === 'custom'" v-model="condition.name" type="text" class="flex-1 input" id="pricing_item_name" />

                      <select v-model="condition.condition" class="input w-[80px]">
                        <option :value="null">{{ $t('helpers.select') }}</option>
                        <option value="include">{{ $t('supplier/pricing_item.special_discount.form.conditions.include') }}</option>
                        <option value="exclude">{{ $t('supplier/pricing_item.special_discount.form.conditions.exclude') }}</option>
                      </select>
                      <input v-model="condition.description" :placeholder="$t('supplier/pricing_item.special_discount.form.condition_placeholder')" type="text" class="flex-1 input" id="pricing_item_name" />

                      <button @click="removeCondition(index)" class="flex items-center">
                        <IconSvg icon="delete" class="size-6 fill-red-500"></IconSvg>
                      </button>
                    </div>
                  </div>
                  <button @click="addCondition" class="btn btn-sm btn-primary">
                    <IconSvg icon="new" class="size-4"></IconSvg>
                    {{ $t('supplier/pricing_item.special_discount.form.add_condition_button') }}
                  </button>
                </div>
              </div>

              <div class="grid grid-cols-12 gap-5">
                <div class="self-center col-span-2">
                  <label for="pricing_item_name" class="ml-3 required">
                    {{ $t("supplier/pricing_item.special_discount.form.discount") }}
                  </label>
                </div>
                <div class="col-span-10 space-y-5">
                  <div class="flex items-center gap-2">
                    <select v-model="specialDiscount.special_discount_type" class="input w-[120px]">
                      <option :value="null">{{ $t('helpers.select') }}</option>
                      <option value="unit_price">{{ $t('supplier/pricing_item.special_discount.form.special_discount_types.unit_price') }}</option>
                      <option value="discount">{{ $t('supplier/pricing_item.special_discount.form.special_discount_types.discount') }}</option>
                    </select>

                    <label for="discount_type_unified" class="custom-radio">
                      <input v-model="specialDiscount.discount_type" type="radio" id="discount_type_unified" value="unified" />
                      {{ $t("supplier/pricing_item.special_discount.form.discount_types.unified") }}
                      <span class="checkmark"></span>
                    </label>
                    <label for="discount_type_grade" class="custom-radio">
                      <input v-model="specialDiscount.discount_type" type="radio" id="discount_type_grade" value="grade" />
                      {{ $t("supplier/pricing_item.special_discount.form.discount_types.grade") }}
                      <span class="checkmark"></span>
                    </label>
                  </div>

                  <template v-if="specialDiscount.discount_type === 'unified'">
                    <div class="flex items-center gap-2">
                      <template v-if="specialDiscount.special_discount_type === 'unit_price'">
                        <label for="unified_unit_price" class="ml-3">
                          {{ $t("supplier/pricing_item.special_discount.form.price") }}
                        </label>
                        <input v-model="specialDiscount.unified_unit_price" type="text" class="input w-[100px]" id="unified_unit_price" />
                      </template>
                      <template v-else-if="specialDiscount.special_discount_type === 'discount'">
                        <label for="grade_discount" class="ml-3">
                          {{ $t("supplier/pricing_item.special_discount.form.discount") }}
                        </label>

                        <div class="flex items-center gap-2">
                          {{ $t('pricing_item.special_discount.form.discount_desc1') }}
                          <input v-model="specialDiscount.grade_discount" type="text" class="input w-[100px]" id="grade_discount" />
                          {{ $t('supplier/pricing_item.special_discount.form.discount_desc2') }}
                        </div>
                      </template>
                    </div>
                  </template>
                  <template v-else-if="specialDiscount.discount_type === 'grade'">
                    <div class="flex items-center gap-2">
                      <label for="grade_item" class="ml-3 required">
                        {{ $t("supplier/pricing_item.special_discount.form.grade_item") }}
                      </label>
                      <input v-model="specialDiscount.grade_item" type="text" class="input w-[200px]" id="grade_item" />
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </template>
        </ConfirmModal>
      </template>
      <template #item="{ resource }">
        <div class="grid grid-cols-12 gap-2 px-5 py-4 bg-white rounded shadow-lg">
          <div class="self-center col-span-1 font-bold text-gray-700">
            {{ $t('supplier/pricing_item.special_discount.form.desc') }}
          </div>
          <div class="self-center col-span-4 text-gray-500">
            {{ resource.name }}
          </div>
          <div class="self-center col-span-1 font-bold text-gray-700">
            {{ $t('supplier/pricing_item.special_discount.form.condition') }}
          </div>
          <div class="self-center col-span-2 text-gray-500 truncate">
            <template v-if="resource.conditions.length > 1">
              {{ $t('supplier/pricing_item.special_discount.form.multiple_conditions') }}({{ resource.conditions.length }})
            </template>
            <template v-else>
              {{ $t('supplier/pricing_item.special_discount.form.condition_types.' + resource.conditions[0].condition_type) }}
              {{ $t('supplier/pricing_item.special_discount.form.conditions.' + resource.conditions[0].condition) }}
              {{ resource.conditions[0].description }}
            </template>
          </div>
          <div class="self-center col-span-1 font-bold text-gray-700">
            {{ $t('supplier/pricing_item.special_discount.form.discount') }}
          </div>
          <div class="self-center col-span-2 text-gray-500">
            {{ $t('supplier/pricing_item.special_discount.form.special_discount_types.' + resource.special_discount_type) }}
            {{ $t('supplier/pricing_item.special_discount.form.discount_types.' + resource.discount_type) }}
          </div>
          <div class="flex justify-end col-span-auto">
            <button @click="removeSpecialDiscount(index)">
              <IconSvg icon="delete" class="size-6 fill-red-500"></IconSvg>
            </button>
          </div>
        </div>
      </template>
    </DataTable>
  </div>
</template>
<script setup>
import { useForm, usePage } from "@inertiajs/vue3"
import { ref, computed } from "vue"

const props = defineProps({
  product: Object,
  contract: Object,
  pricing_item: Object,
  exclude_discounts: Array,
  options_for_select: Object,
});

const tabList = usePage().props.sub_menu_lists;

const search = ref('')

const excludeDiscountsFilter = computed(() => {
  return props.exclude_discounts.filter(item => item.name.includes(search.value))
})

const form = useForm({
  pricing_item: {
    name: null,
    account_number: null,
    currency: null,
    exchange_rate: null,
    upstream_technical_support: null,
    email: null,
    communication_software: null,
    group_name: null,
    service_fee: null,
    general_discount: null,
  },
})

// const specialDiscounts = ref([])
const specialDiscounts = ref([
  { "name": "haha", "conditions": [ { "name": null, "condition_type": "product_service", "condition": "include", "description": "yoyo" } ], "special_discount_type": "unit_price", "discount_type": "unified", "unified_unit_price": "10", "unified_discount": null, "grade_discount": null, "grade_item": null },
  { "name": "haha", "conditions": [ { "name": null, "condition_type": "product_service", "condition": "include", "description": "yoyo" } ], "special_discount_type": "unit_price", "discount_type": "unified", "unified_unit_price": "10", "unified_discount": null, "grade_discount": null, "grade_item": null },
])

const specialDiscount = ref({
  name: null,
  conditions: [],
  special_discount_type: 'unit_price',
  discount_type: 'unified',
  unified_unit_price: null,
  unified_discount: null,
  grade_discount: null,
  grade_item: null,
})

const addCondition = () => {
  specialDiscount.value.conditions.push({
    name: null,
    condition_type: null,
    condition: null,
    description: null,
  })
}
const removeCondition = (index) => {
  specialDiscount.value.conditions.splice(index, 1)
}

const addSpecialDiscount = () => {
  specialDiscounts.value.push(specialDiscount.value)
}

const removeSpecialDiscount = (index) => {
  specialDiscounts.value.splice(index, 1)
}
</script>
