<template>
  <Oauth v-if="isOauth" :authenticity_token="authenticity_token" />
  <div v-else class="space-y-6">
    <PasswordInput
      v-if="showPasswordInput"
      :email="loginForm.user.email"
      @submit="loginFormSubmit"
    />
    <EmailCheck
      v-else
      :is-checking="isChecking"
      @submit="handleEmailCheck"
    />
  </div>
</template>

<script setup>
import { useForm } from "@inertiajs/vue3";
import { ref } from "vue";
import EmailCheck from "./email_check.vue";
import PasswordInput from "./password_input.vue";
import Oauth from "./oauth.vue";

const showPasswordInput = ref(false);
const isChecking = ref(false);

const isOauth = ref(false);
const authenticity_token = ref("");

const checkEmailForm = useForm({
  email: "",
});
const handleEmailCheck = (email) => {
  isChecking.value = true;
  checkEmailForm.email = email;
  checkEmailForm.post("/check_email", {
    preserveState: true,
    preserveScroll: true,
    onSuccess: (page) => {
      isChecking.value = false;
      loginForm.user.email = page.props.user.email;
      isOauth.value = page.props.user.is_sso;
      showPasswordInput.value = !isOauth.value;
      authenticity_token.value = page.props.authenticity_token;
    },
  });
};

const loginForm = useForm({
  user: {
    email: "",
    password: "",
  },
});
const loginFormSubmit = (password) => {
  loginForm.user.password = password;
  loginForm.post("/users/sign_in", {
    preserveState: true,
    preserveScroll: true,
  });
};

</script>
