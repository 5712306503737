<template>
  <div class="flex items-center justify-between col-span-1 px-5 py-3 bg-white">
    <label
      :for="`exclude_discount-select-${exclude_discount.id}`"
      class="custom-checkbox"
    >
      <input
        type="checkbox"
        :id="`exclude_discount-select-${exclude_discount.id}`"
        v-model="selected"
      />
      {{ exclude_discount.name }}
      <span class="checkmark checkmark-sm"></span>
    </label>
    <div class="flex items-center gap-3">
      <ConfirmModal
        :modal-cancel-text="$t('helpers.back')"
        :modal-submit-text="$t('helpers.save')"
        :modal-title="$t('supplier/exclude_discount.index.title')"
        modalSubmitClass="btn btn-sm btn-primary"
        @submit="updateExcludeDiscount"
        :prevent-submit="!canSubmit"
        class="flex items-center my-3"
      >
        <template #button>
          <IconSvg icon="edit" class="fill-primary"></IconSvg>
        </template>
        <dl class="grid grid-cols-4 gap-5 my-5">
          <dt class="flex items-center col-span-1">
            <label :for="`exclude_discount_name-${exclude_discount.id}`" class="ml-3 required">{{
              $t("activerecord.attributes.supplier/exclude_discount.name")
            }}</label>
          </dt>
          <dd class="flex items-center justify-between col-span-3">
            <input
              class="input"
              :id="`exclude_discount_name-${exclude_discount.id}`"
              v-model="form.exclude_discount.name"
            />
          </dd>
        </dl>
      </ConfirmModal>

      <Link
        :href="`/products/${props.product_id}/exclude_discounts/${exclude_discount.id}`"
        class="flex items-center"
        method="delete"
        as="button"
      >
        <IconSvg icon="delete" class="fill-warning"></IconSvg>
      </Link>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, onMounted, watch } from "vue";
import { useForm } from "@inertiajs/vue3";
const props = defineProps({
  product_id: String,
  exclude_discount: Object,
});

const form = useForm({
  exclude_discount: {
    name: props.exclude_discount.name,
  },
});

const updateExcludeDiscount = () => {
  form.put(
    `/products/${props.product_id}/exclude_discounts/${props.exclude_discount.id}`
  );
};

const canSubmit = computed(() => {
  return form.exclude_discount.name.length > 0;
});

const selected = ref(false);

const emit = defineEmits(["submit"]);

onMounted(() => {
  watch(selected, (newVal) => {
    emit("submit", { id: props.exclude_discount.id, selected: newVal });
  });
});
</script>
