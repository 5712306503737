<template>
  <form @submit.prevent="formSubmit">
    <div class="card">
      <div class="card-body">
        <div class="flex">
          <div
            class="flex flex-col items-start justify-between gap-3 mx-3 min-w-[550px]"
          >
            <div class="">
              <div class="flex items-center gap-1">
                <label for="email" class="required">
                  {{ $t("activerecord.attributes.user.email") }}</label
                >
                <div>
                  <input
                    type="email"
                    class="input"
                    id="email"
                    v-model="form.user.email"
                    required
                    :disabled="userChecked"
                    :class="{
                      error:
                        form.user.email &&
                        (!emailValidate(form.user.email) || !userChecked),
                    }"
                  />
                </div>
                <template v-if="userChecked">
                  <span
                    v-if="freshUser"
                    class="flex items-center gap-1 text-sm text-gray-40"
                  >
                    <IconSvg icon="status_yes" class="fill-secondary"></IconSvg>
                    (創建後自動發送驗證信)
                  </span>
                  <span
                    v-else
                    class="flex items-center gap-1 text-sm text-gray-40"
                  >
                    <IconSvg icon="info" class="fill-secondary"></IconSvg>
                    已存在的帳號 (將推送該用戶確認通知)
                  </span>
                </template>
                <button
                  class="btn btn-xs btn-primary"
                  @click="handleEmailCheck"
                  type="button"
                  :disabled="
                    !form.user.email ||
                    !emailValidate(form.user.email) ||
                    userChecked
                  "
                  v-else
                >
                  檢查
                </button>
              </div>
              <!-- 預留錯誤訊息空間，錯誤訊息出現時，不影響排版 -->
              <p class="w-full h-5 text-sm text-right pe-11 text-warning">
                <template v-if="!userChecked">
                  <template
                    v-if="form.user.email && !emailValidate(form.user.email)"
                  >
                    {{ $t("errors.format") }}
                  </template>
                  <template v-else-if="form.user.email && !userChecked">
                    {{ $t("errors.not_checked") }}
                  </template>
                </template>
              </p>
            </div>
            <div>
              <div class="flex items-center gap-1">
                <label for="cellphone">{{
                  $t("activerecord.attributes.user.cellphone")
                }}</label>
                <div>
                  <input
                    type="text"
                    v-maska:unmaskedCellphone.unmasked="'####-###-###'"
                    :disabled="!freshUser"
                    class="input"
                    id="cellphone"
                    v-model="maskedCellphone"
                    :class="{
                      error:
                        freshUser && !cellphoneValidate(form.user.cellphone),
                    }"
                  />
                </div>
                <span
                  v-if="freshUser"
                  class="flex items-center gap-1 text-sm text-gray-40"
                >
                  (帳號開通後才可驗證)
                </span>
              </div>
              <p class="w-full h-5 text-sm text-center text-warning">
                <template
                  v-if="freshUser && !cellphoneValidate(form.user.cellphone)"
                >
                  <!-- {{ $t("errors.format") }} -->
                </template>
              </p>
            </div>
          </div>
          <div class="flex flex-col items-start justify-between gap-3 mx-3">
            <div>
              <div class="flex items-center gap-1">
                <label for="name" class="required">{{
                  $t("activerecord.attributes.user.name")
                }}</label>
                <div>
                  <input
                    type="text"
                    class="input"
                    :disabled="!freshUser"
                    id="name"
                    v-model="form.user.name"
                    required
                    :class="{
                      error: freshUser && !form.user.name,
                    }"
                  />
                </div>
              </div>
              <p class="h-5"></p>
            </div>
            <div>
              <div class="flex items-center gap-1">
                <label for="phone" class="form-label">{{
                  $t("activerecord.attributes.user.phone")
                }}</label>
                <div>
                  <input
                    type="text"
                    class="input"
                    :disabled="!freshUser"
                    id="phone"
                    v-model="form.user.phone"
                  />
                </div>
              </div>
              <p class="h-5"></p>
            </div>
          </div>
        </div>
        <hr class="border-gray-40" />

        <TabNav :tab-list="tabList">
          <TabAuthentication
            @submit="modifyUser"
            :user="form.user"
            :current_sign_in_at="props.user.current_sign_in_at"
            :current_sign_in_ip="props.user.current_sign_in_ip"
            v-if="tabList.find((tab) => tab.id === 'authentication').enable"
          />

          <TabGroup v-if="tabList.find((tab) => tab.id === 'group').enable" />

          <TabPermission
            @submit="modifyUser"
            :user="form.user"
            v-if="tabList.find((tab) => tab.id === 'permission').enable"
          />

          <TabResource
            @submit="modifyUser"
            :user="form.user"
            v-if="tabList.find((tab) => tab.id === 'resource').enable"
          />

          <TabVersion
            @submit="modifyUser"
            :user="form.user"
            v-if="tabList.find((tab) => tab.id === 'version').enable"
          />
        </TabNav>
      </div>
      <div class="card-footer">
        <FormAction
          :can-delete="false"
          :can-submit="canSubmit"
          :back-url="'/users'"
          :has-modified="form.isDirty"
          :submit-text="$t('helpers.create')"
          :processing="form.processing"
        />
      </div>
    </div>
  </form>
  <AlertModal :open="openAlertModal" :url="'/users'">
    <template #title>
      <IconSvg icon="status_yes" class="fill-white"></IconSvg>
      <h3 class="text-xl font-bold text-white">
        {{
          freshUser
            ? $t("user.create.fresh.title")
            : $t("user.create.exist.title")
        }}
      </h3>
    </template>
    <template #content>
      <p>
        {{
          freshUser
            ? $t("user.create.fresh.message")
            : $t("user.create.exist.message")
        }}
      </p>
    </template>
  </AlertModal>
</template>

<script setup>
import { useForm, router } from "@inertiajs/vue3";
import { computed, ref, watch } from "vue";
import { vMaska } from "maska/vue";
import FormAction from "@/components/form_action.vue";
import TabAuthentication from "./tab_authentication.vue";
import TabGroup from "./tab_group.vue";
import TabPermission from "./tab_permission.vue";
import TabResource from "./tab_resource.vue";
import TabVersion from "./tab_version.vue";

const tabList = [
  {
    id: "authentication",
    i18n_key: "user.sub_nav.authentication",
    enable: true,
  },
  {
    id: "group",
    i18n_key: "user.sub_nav.group",
    enable: false,
  },
  {
    id: "permission",
    i18n_key: "user.sub_nav.permission",
    enable: false,
  },
  {
    id: "resource",
    i18n_key: "user.sub_nav.resource",
    enable: false,
  },
  {
    id: "version",
    i18n_key: "user.sub_nav.version",
    enable: false,
  },
];

const props = defineProps({
  user: {
    email: String,
    name: String,
    cellphone: String,
    phone: String,
    enable: Boolean,
  },
});

const maskedCellphone = ref("");
const unmaskedCellphone = ref("");
defineExpose({ unmaskedCellphone: unmaskedCellphone });

const form = useForm({
  user: {
    email: "",
    name: "",
    cellphone: "",
    phone: "",
    enable: true,
  },
});

const userChecked = ref(false);
const freshUser = ref(false);
const openAlertModal = ref(false);
const canSubmit = computed(() => {
  return userChecked.value && !!form.user.name && !!form.user.cellphone;
});

const checkEmailForm = useForm({
  user: {
    email: "",
  },
});

const handleEmailCheck = () => {
  userChecked.value = true;
  checkEmailForm.user.email = form.user.email;
  checkEmailForm.post("/users/check", {
    preserveState: true,
    preserveScroll: true,
    onSuccess: (page) => {
      if (page.props.user.id) {
        form.user = page.props.user;
        maskedCellphone.value = page.props.user.cellphone;
      } else {
        freshUser.value = true;
      }
    },
  });
};

watch(unmaskedCellphone, (newValue, oldValue) => {
  form.user.cellphone = newValue;
});

const formSubmit = () => {
  form.post("/users", {
    onSuccess: () => {
      router.visit("/users/new", {
        preserveState: true,
        preserveScroll: true,
      });
      openAlertModal.value = true;
    },
  });
};

const modifyUser = (new_value) => {
  Object.assign(form.user, new_value);
};
</script>
