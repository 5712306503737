<template>
  <ConfirmModal
    :disabled="download_ids.length === 0"
    :modal-cancel-text="$t('helpers.back')"
    :modal-submit-text="$t('helpers.download', { text: '' })"
    modal-submit-class="btn btn-sm btn-primary"
    :modal-title="
      $t('helpers.download', {
        text: `${$t('nav.cloud_account_id')}${$t('helpers.list')}`,
      })
    "
    modal-size="xl"
    @submit="downloadCloudAccounts"
    class="btn btn-sm btn-primary"
  >
    <template #sub-title>
      <button
        class="btn btn-sm btn-outline btn-primary"
        type="button"
        @click="clearDownloadColumns"
      >
        <IconSvg icon="status_no" class="w-4 h-4 fill-primary-dark"></IconSvg>
        {{ $t("cloud_account.download_list.clear_all") }}
      </button>
    </template>
    <template #button>
      <IconSvg icon="download" class="w-4 h-4 fill-white"></IconSvg>
      {{
        $t("helpers.download", {
          text: `${$t("nav.cloud_account_id")}${$t("helpers.list")}`,
        })
      }}
    </template>

    <div class="grid grid-cols-6 gap-10">
      <div class="flex flex-col gap-2">
        <h5 class="text-sm font-bold text-gray-80">帳號基本資料</h5>
        <label for="download_cloud_account_account_id" class="custom-checkbox">
          <input
            type="checkbox"
            id="download_cloud_account_account_id"
            class="download-column"
            v-model="download_columns"
            value="account_id"
            disabled
          />
          {{ $t("cloud_account.index.account_id") }}
          <span class="checkmark"></span>
        </label>

        <label for="download_product_main_category" class="custom-checkbox">
          <input
            type="checkbox"
            id="download_product_main_category"
            class="download-column"
            v-model="download_columns"
            value="main_category"
          />
          {{ $t("cloud_account.index.main_category") }}
          <span class="checkmark"></span>
        </label>

        <label for="download_product_summary_name" class="custom-checkbox">
          <input
            type="checkbox"
            id="download_product_summary_name"
            class="download-column"
            v-model="download_columns"
            value="summary_name"
          />
          {{ $t("cloud_account.index.summary_name") }}
          <span class="checkmark"></span>
        </label>

        <label for="download_product_name" class="custom-checkbox">
          <input
            type="checkbox"
            id="download_product_name"
            class="download-column"
            v-model="download_columns"
            value="name"
          />
          {{ $t("cloud_account.index.name") }}
          <span class="checkmark"></span>
        </label>

        <label for="download_cloud_account_email_name" class="custom-checkbox">
          <input
            type="checkbox"
            id="download_cloud_account_email_name"
            class="download-column"
            v-model="download_columns"
            value="email_name"
          />
          {{ $t("cloud_account.index.email_name") }}
          <span class="checkmark"></span>
        </label>

        <label for="download_cloud_account_status" class="custom-checkbox">
          <input
            type="checkbox"
            id="download_cloud_account_status"
            class="download-column"
            v-model="download_columns"
            value="status"
          />
          {{ $t("cloud_account.index.status") }}
          <span class="checkmark"></span>
        </label>

        <label for="download_cloud_account_testing_duration" class="custom-checkbox">
          <input
            type="checkbox"
            id="download_cloud_account_testing_duration"
            class="download-column"
            v-model="download_columns"
            value="testing_duration"
          />
          {{ $t("cloud_account.index.testing_duration") }}
          <span class="checkmark"></span>
        </label>

        <label for="download_cloud_account_start_at" class="custom-checkbox">
          <input
            type="checkbox"
            id="download_cloud_account_start_at"
            class="download-column"
            v-model="download_columns"
            value="start_at"
          />
          {{ $t("cloud_account.index.start_at") }}
          <span class="checkmark"></span>
        </label>

        <label for="download_cloud_account_end_at" class="custom-checkbox">
          <input
            type="checkbox"
            id="download_cloud_account_end_at"
            class="download-column"
            v-model="download_columns"
            value="end_at"
          />
          {{ $t("cloud_account.index.end_at") }}
          <span class="checkmark"></span>
        </label>

        <label for="download_cloud_account_amount_currency" class="custom-checkbox">
          <input
            type="checkbox"
            id="download_cloud_account_amount_currency"
            class="download-column"
            v-model="download_columns"
            value="amount_currency"
          />
          {{ $t("cloud_account.index.amount_currency") }}
          <span class="checkmark"></span>
        </label>

        <label for="download_cloud_account_amount" class="custom-checkbox">
          <input
            type="checkbox"
            id="download_cloud_account_amount"
            class="download-column"
            v-model="download_columns"
            value="amount"
          />
          {{ $t("cloud_account.index.amount") }}
          <span class="checkmark"></span>
        </label>
      </div>
      <div class="flex flex-col gap-2">
        <h5 class="text-sm font-bold text-gray-80">客戶基本資料</h5>

        <h5 class="text-sm font-bold text-gray-80">技術支援方式</h5>
      </div>
      <div class="flex flex-col gap-2">
        <h5 class="text-sm font-bold text-gray-80">客戶商業條件</h5>
      </div>
      <div class="flex flex-col gap-2">
        <h5 class="text-sm font-bold text-gray-80">客戶關連合約紀錄</h5>
      </div>
      <div class="flex flex-col gap-2">
        <h5 class="text-sm font-bold text-gray-80">果核成本條件</h5>
        <label for="download_cloud_account_hide_origin_credit" class="custom-checkbox">
          <input
            type="checkbox"
            id="download_cloud_account_hide_origin_credit"
            class="download-column"
            v-model="download_columns"
            value="hide_origin_credit"
          />
          {{ $t("cloud_account.index.hide_origin_credit") }}
          <span class="checkmark"></span>
        </label>
      </div>
      <div class="flex flex-col gap-2">
        <h5 class="text-sm font-bold text-gray-80">成本關連合約紀錄</h5>
      </div>
    </div>
  </ConfirmModal>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";

const props = defineProps({
  authenticity_token: String,
  download_ids: Array,
});

const download_ids = computed(() => {
  return props.download_ids;
});

const download_columns = ref([]);

const clearDownloadColumns = () => {
  download_columns.value = ["account_id"];
};

const emit = defineEmits(["submit"]);

const downloadCloudAccounts = () => {
  const form = document.createElement("form");
  form.method = "POST";
  form.action = "/cloud_accounts/download.csv";

  const csrfToken = document.createElement("input");
  csrfToken.type = "hidden";
  csrfToken.name = "authenticity_token";
  csrfToken.value = props.authenticity_token;

  document.body.appendChild(form);
  form.appendChild(csrfToken);

  download_ids.value.forEach((id) => {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = "ids[]";
    input.value = id;
    form.appendChild(input);
  });
  download_columns.value.forEach((column) => {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = "download_columns[]";
    input.value = column;
    form.appendChild(input);
  });
  form.submit();
  form.remove();
};

onMounted(() => {
  document.querySelectorAll(".download-column").forEach((checkbox) => {
    download_columns.value.push(checkbox.value);
  });
});
</script>
