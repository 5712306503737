<template>
  <button
    type="button"
    class="hs-dropdown-toggle"
    aria-haspopup="menu"
    aria-expanded="false"
    :id="dropdownId"
  >
    <slot name="custom-trigger"></slot>

    <template v-if="!hasCustomTriggerSlot">
      <span class="nav-text">{{ buttonText }}</span>
      <IconSvg icon="arrow_down" class="w-4 h-4 hs-dropdown-open:rotate-180" />
    </template>
  </button>
  <div
    class="hidden hs-dropdown-menu"
    role="menu"
    aria-orientation="vertical"
    :aria-labelledby="dropdownId"
  >
    <slot></slot>
  </div>
</template>

<script setup>
import { useSlots, computed, getCurrentInstance } from "vue";

const instance = getCurrentInstance();
const dropdownId = `dropdown-${instance.uid}`;

const slots = useSlots();
const hasCustomTriggerSlot = computed(() => {
  return !!slots["custom-trigger"];
});

const props = defineProps({
  buttonText: {
    type: String,
  },
});
</script>
