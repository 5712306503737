<template>
  <div class="card">
    <DataTable :resources="users" :paginate="paginate">
      <template #actions>
        <Link href="/users/new" class="btn btn-sm btn-primary">
          <IconSvg icon="new" class="w-4 h-4"></IconSvg>
          {{ $t("helpers.new", { model: $t("activerecord.models.user") }) }}
        </Link>
      </template>
      <template #thead>
        <DataTableHead
          v-for="thead in theads"
          :id="thead.id"
          :text="$t(`activerecord.attributes.user.${thead.i18n_key}`)"
          :defaultOrderColumn="thead.defaultOrderColumn"
          :disableOrder="thead.disableOrder"
        >
        </DataTableHead>
      </template>
      <template #tbody-tr="{ resource }">
        <td>
          <Link class="text-gray-60" :href="`/users/${resource.id}/edit`">{{
            resource.email
          }}</Link>
        </td>
        <td>{{ resource.name }}</td>
        <td>{{ resource.enable }}</td>
        <td>
          <span class="flex items-center gap-1">
            <span> {{ $t(`user.confirmed.${resource.has_confirmed}`) }}</span>
            <IconSvg
              icon="status_yes"
              class="w-4 h-4 fill-secondary"
              v-if="resource.has_confirmed"
            ></IconSvg>
            <IconSvg
              icon="status_no"
              class="w-4 h-4 fill-warning"
              v-else
            ></IconSvg>
          </span>
        </td>
        <td>
          <span
            v-for="(user_group, index) in resource.user_groups"
            :key="index"
          >
            {{ user_group }}
            {{ index < resource.user_groups.length - 1 ? ", " : "" }}
          </span>
        </td>
        <td>{{ resource.current_sign_in_at }}</td>
        <td>{{ resource.current_sign_in_ip }}</td>
        <td>{{ resource.created_at }}</td>
      </template>
    </DataTable>
  </div>
</template>

<script setup>
defineProps({
  users: {
    type: Array,
    required: true,
  },
  paginate: {
    type: Object,
    required: true,
  },
});

const theads = [
  {
    id: "email",
    i18n_key: "email",
    defaultOrderColumn: true,
  },
  {
    id: "name",
    i18n_key: "name",
    disableOrder: true,
  },
  {
    id: "enable",
    i18n_key: "enable",
    disableOrder: true,
  },
  {
    id: "confirmed",
    i18n_key: "confirmed",
    disableOrder: true,
  },
  {
    id: "user_group",
    i18n_key: "user_group_ids",
    disableOrder: true,
  },
  {
    id: "current_sign_in_at",
    i18n_key: "current_sign_in_at",
    disableOrder: true,
  },
  {
    id: "current_sign_in_ip",
    i18n_key: "current_sign_in_ip",
    disableOrder: true,
  },
  {
    id: "created_at",
    i18n_key: "created_at",
    disableOrder: true,
  },
];
</script>
