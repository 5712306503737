<template>
  <div
    id="contract-content"
    role="tabpanel"
    aria-labelledby="contract-tab"
    class="hidden"
  >
    <div class="flex items-center justify-end gap-2 mb-3">
      <div class="relative">
        <label for="simple-search" class="relative block">
          <IconSvg
            icon="search"
            class="w-4 h-4 fill-gray-80 with-input"
          ></IconSvg>

          <input
            v-model="search"
            type="text"
            id="simple-search"
            class="input with-icon"
            :placeholder="$t('helpers.search')"
          />
        </label>
      </div>
    </div>
    <div class="gap-5 px-5 py-3 -mx-5 border-t bg-gray-5 border-gray-20">
      <div class="grid grid-cols-12 gap-5">
        <div class="col-span-2">
          <div
            class="py-0 border-t-0 border-b-0 border-l-0 border-r shadow-none card bg-gray-5"
          >
            <div class="text-center border-b-0 card-header">
              <h5 class="text-lg font-bold">成本關聯紀錄</h5>
            </div>
            <div class="card-body">
              <div class="flex flex-col items-end">
                <p class="card-title text-primary-dark">
                  {{ $t("activerecord.attributes.product.name") }}
                </p>
                <p class="mt-3 text-gray-60">
                  {{ $t("activerecord.attributes.supplier/contract.name") }}
                </p>
                <p class="mt-3 text-gray-60">
                  {{ $t("activerecord.models.supplier/pricing_item") }}
                </p>
                <p class="mt-3 text-gray-60">
                  {{
                    $t(
                      "activerecord.attributes.supplier/pricing_item.service_fee_percent"
                    )
                  }}%
                </p>
                <p class="mt-3 text-gray-60">
                  {{
                    $t(
                      "activerecord.attributes.supplier/pricing_item.common_discount_percent"
                    )
                  }}%
                </p>
                <p class="mt-3 text-gray-60">特殊折扣</p>
              </div>
            </div>
            <div class="card-footer">
                <div class="flex flex-col items-end">
                  <p class="text-gray-60">合約期間</p>
                  <p class="mt-3 text-gray-60">關聯期間</p>
                </div>
              </div>
          </div>
        </div>
        <div class="col-span-10 overflow-x-scroll">
          <div class="flex items-center gap-5">
            <div
              v-for="(contract, index) in filteredContracts"
              :key="index"
              class="py-0 border-none card bg-gray-5"
            >
              <div class="card-header">
                <h5 class="text-lg font-bold">&nbsp;</h5>
              </div>
              <div class="bg-white card-body">
                <p class="card-title text-primary-dark">{{ contract.name }}</p>

                <p class="text-gray-60">{{ contract.product_name }}</p>

                <p class="text-gray-60">{{ contract.pricing_item }}</p>

                <p class="text-gray-60">{{ contract.service_fee }}%</p>

                <p class="text-gray-60">{{ contract.normal_discount }}%</p>

                <p class="text-gray-60">{{ contract.special_discount }}</p>
              </div>
              <div class="bg-white card-footer">
                <p class="text-gray-60">
                  {{ contract.contract_period_start }} ~
                  {{ contract.contract_period_end }}
                </p>
                <p class="mt-3 text-gray-60">
                  {{ contract.relation_period_start }} ~
                  {{ contract.relation_period_end }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, ref } from "vue";
const props = defineProps({
  cloudAccount: Object,
});

// 模擬合約資料
const contracts = [
  {
    product_name: "-AWS(伊雲谷HK)",
    name: "AA",
    pricing_item: "項目BB",
    service_fee: "3.00",
    normal_discount: "5.00",
    special_discount: "條件(3)",
    contract_period_start: "2024/01",
    contract_period_end: "2024/12",
    relation_period_start: "2024/01",
    relation_period_end: "2024/04",
  },
  {
    product_name: "-AWS(伊雲谷HK)",
    name: "BB",
    pricing_item: "項目CC",
    service_fee: "3.00",
    normal_discount: "5.00",
    special_discount: "條件(3)",
    contract_period_start: "2024/01",
    contract_period_end: "2024/12",
    relation_period_start: "2024/01",
    relation_period_end: "2024/04",
  },
];

const search = ref("");

const filteredContracts = computed(() => {
  return contracts.filter((contract) => {
    return contract.name.includes(search.value);
  });
});
</script>
