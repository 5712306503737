<template>
  <div class="px-5 py-3 my-5 bg-white rounded shadow-sm max-w-[1000px]" :class="{ 'ml-auto': reverse_layout }">
    <div class="flex items-center justify-between" :class="{ 'flex-row-reverse': reverse_layout }">

      <p class="text-sm text-gray-80">{{ comment.updated_at }}</p>

      <span
        class="px-3 py-1 text-xs text-white rounded"
        :class="{
          'bg-gray-40': comment.category === 'restricted',
          'bg-secondary': comment.category === 'global',
        }"
        >{{ $t(`comment.category.${comment.category}`) }}</span
      >
    </div>
    <div class="flex items-center justify-between" :class="{ 'flex-row-reverse': reverse_layout }">
      <h5 class="my-3 text-base font-bold text-gray-80">{{ comment.user.name }}</h5>
    </div>

    <pre class="text-sm text-gray-80">{{ comment.content }}</pre>

    <div class="flex items-center justify-end gap-5" v-if="canEdit">
      <ConfirmModal
        :button-text="$t('helpers.edit', { model: '' })"
        :modal-cancel-text="$t('helpers.back')"
        :modal-submit-text="$t('helpers.save')"
        :modal-title="
          $t('helpers.edit', { model: $t('activerecord.models.comment') })
        "
        modalSubmitClass="btn btn-sm btn-primary"
        @submit="updateComment"
        :prevent-submit="!canSubmit"
        class="px-10 my-3 btn btn-sm btn-primary"
      >
        <dl class="grid grid-cols-4 gap-5 my-5">
          <dt class="flex items-center col-span-1">
            <label for="comment_category" class="ml-3 required">{{
              $t("activerecord.attributes.comment.category")
            }}</label>
          </dt>
          <dd class="flex items-center justify-between col-span-3">
            <div>
              <Multiselect
                v-model="form.comment.category"
                :show-labels="false"
                :searchable="false"
                :allow-empty="false"
                :options="['restricted', 'global']"
              >
                <template #singleLabel="props">
                  {{ $t(`comment.category.${props.option}`) }}
                </template>
                <template #option="props">
                  {{ $t(`comment.category.${props.option}`) }}
                </template>
                <template #caret>
                  <IconSvg
                    icon="arrow_down"
                    class="absolute w-4 h-4 -translate-y-1/2 top-1/2 right-2 fill-gray-80"
                  ></IconSvg>
                </template>
              </Multiselect>
            </div>
          </dd>
          <dt class="flex items-center col-span-1">
            <label for="comment_content" class="ml-3 required">{{
              $t("activerecord.attributes.comment.content")
            }}</label>
          </dt>
          <dd class="flex items-center justify-between col-span-3">
            <textarea
              class="input"
              id="comment_content"
              rows="5"
              v-model="form.comment.content"
            ></textarea>
          </dd>
        </dl>
      </ConfirmModal>

      <ConfirmModal
        :button-text="$t('helpers.delete', { model: '' })"
        :modal-cancel-text="$t('helpers.back')"
        :modal-submit-text="$t('helpers.delete')"
        :modal-title="
          $t('helpers.delete', { model: $t('activerecord.models.comment') })
        "
        modalSubmitClass="btn btn-sm btn-primary"
        @submit="deleteComment"
        class="px-10 my-3 btn btn-sm btn-warning"
      >
      <p>
          {{
            $t("delete_confirm.message", {
              target: $t('activerecord.models.comment'),
              from: $t('cloud_account.sub_nav.comment'),
            })
          }}
        </p>
    </ConfirmModal>
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue';
import { useForm, usePage } from '@inertiajs/vue3';
const props = defineProps({
  comment: Object,
  reverse_layout: { type: Boolean, default: false },
});

const form = useForm({
  comment: {
    category: props.comment.category,
    content: props.comment.content,
  },
});

const canEdit = computed(() => {
  return props.comment.user.email === usePage().props.current_user.email;
});

const canSubmit = computed(() => {
  return form.comment.content.trim().length > 0 && !!form.comment.category;
});

const updateComment = () => {
  form.put(`/cloud_accounts/${props.comment.cloud_account_id}/comments/${props.comment.id}`);
};

const deleteComment = () => {
  form.delete(`/cloud_accounts/${props.comment.cloud_account_id}/comments/${props.comment.id}`);
};
</script>
