<template>
  <div class="page-header">
    <!-- Breadcrumb -->
    <nav v-if="hasBreadcrumb" class="flex" aria-label="Breadcrumb">
      <ol class="inline-flex items-center">
        <slot name="breadcrumb"></slot>
      </ol>
    </nav>
  </div>

  <Head v-if="headerTitle" :title="headerTitle"></Head>
</template>

<script setup>
import { computed, useSlots } from 'vue'
import { Head } from '@inertiajs/vue3'

const props = defineProps({ title: { type: String } })

const slots = useSlots()

const hasBreadcrumb = computed(() => {
  return !!slots.breadcrumb
})

const hasFunctional = computed(() => {
  return !!slots.functional
})

const headerTitle = computed(() => {
  const defaultTitle = gon.head_title

  return props.title ? `${props.title} | ${defaultTitle}` : defaultTitle
})
</script>
