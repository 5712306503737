<template>
  <form @submit.prevent="handleEmailCheck">
    <div class="sm:mx-auto sm:w-full sm:max-w-sm">
      <h2 class="mb-10 text-2xl font-bold leading-9 tracking-tight text-left">
        {{ $t("devise.sessions.new.sign_in") }}
      </h2>
    </div>
    <div class="mt-2">
      <label for="email" class="relative block">
        <IconSvg icon="account" class="w-4 h-4 with-input" />
        <input
          v-model="form.email"
          id="email"
          type="email"
          autocomplete="email"
          required
          placeholder="Email"
          class="input with-icon"
          :class="{
            error: form.errors.email,
          }"
        />
      </label>
      <p class="text-right text-warning" v-if="form.errors">
        {{ form.errors.email }}
      </p>
    </div>

    <div class="mt-4">
      <button
        class="w-full btn btn-primary"
        :disabled="!emailValidate(form.email)"
      >
        {{ isChecking ? $t("processing") : $t("next") }}
      </button>
    </div>
  </form>
</template>

<script setup>
import { useForm } from "@inertiajs/vue3";

const props = defineProps({
  isChecking: {
    type: Boolean
  },
});

const form = useForm({
  email: "",
});

const emit = defineEmits(["submit"]);

const handleEmailCheck = () => {
  emit("submit", form.email);
};

</script>
