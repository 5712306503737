<template>
  <form @submit.prevent="handlePasswordInput">
    <div class="mb-4 text-left sm:mx-auto sm:w-full sm:max-w-sm">
      <h2 class="text-2xl font-bold leading-9 tracking-tight">
        {{ $t("greeting") }}
      </h2>

      <h4>{{ props.email }}</h4>
      <Link class="text-sm btn-link" href="/users/sign_in" preserve-scroll>
        {{ $t("devise.sessions.new.change_account") }}
      </Link>
    </div>

    <div class="relative mt-2">
      <label for="password" class="relative block">
        <IconSvg icon="lock" class="w-4 h-4 with-input" />
        <input
          v-model="form.password"
          id="password"
          type="password"
          autocomplete="current-password"
          required
          :placeholder="$t('activerecord.attributes.user.password')"
          class="input with-icon"
        />
      </label>
      <IconSvg
        icon="showtext"
        class="w-4 h-4 cursor-pointer with-input input-end"
        v-if="isPasswordVisible"
        @click="togglePasswordVisibility"
      />
      <IconSvg
        icon="hidetext"
        class="w-4 h-4 cursor-pointer with-input input-end"
        v-else
        @click="togglePasswordVisibility"
      />
    </div>
    <p class="text-right">
      <Link
        class="text-sm btn-link"
        :data="{ email: props.email }"
        href="/users/password/new"
        preserve-scroll
      >
        {{ $t("devise.passwords.new.forgot_your_password") }}
      </Link>
    </p>
    <div class="mt-4">
      <button
        type="submit"
        :disabled="!form.password"
        class="w-full btn"
        :class="{
          'btn-primary': form.password,
        }"
      >
        {{ $t("devise.sessions.new.sign_in") }}
      </button>
    </div>
  </form>
</template>

<script setup>
import { useForm } from "@inertiajs/vue3";
import { ref } from "vue";
const props = defineProps({
  email: {
    type: String,
  },
});
const form = useForm({
  password: "",
});

const isPasswordVisible = ref(false);
const togglePasswordVisibility = () => {
  isPasswordVisible.value = !isPasswordVisible.value;

  if (isPasswordVisible.value) {
    document.querySelector("#password").type = "text";
  } else {
    document.querySelector("#password").type = "password";
  }
};

const emit = defineEmits(["submit"]);
const handlePasswordInput = () => {
  emit("submit", form.password);
};
</script>
