<template>
  <!-- Navbar -->
  <div class="navbar">
    <div class="container">
      <div class="flex items-center justify-between">
        <div class="gap-10 navbar-left">
          <div class="navbar-brand">
            <Link href="/">
              <h4 v-t="'site'"></h4>
            </Link>
          </div>
          <ul class="navbar-nav">
            <template v-for="menu in menu_lists">
              <li
                v-if="menu.items"
                class="hs-dropdown [--strategy:fixed] [--adaptive:none]"
              >
                <DropdownMenu :button-text="menu.text">
                  <Link
                    :href="item.href"
                    v-for="item in menu.items"
                    class="dropdown-item"
                  >
                    {{ item.text }}
                  </Link>
                </DropdownMenu>
              </li>
              <li v-else>
                <Link :href="menu.href">
                  <span class="nav-text">{{ menu.text }}</span>
                </Link>
              </li>
            </template>
          </ul>
        </div>
        <div class="navbar-right">
          <ul class="navbar-nav">
            <li
              class="hs-dropdown [--strategy:fixed] [--adaptive:none] [--auto-close:inside]"
            >
              <DropdownMenu>
                <template #custom-trigger>
                  <IconSvg icon="account" />
                  <span class="nav-text">{{ currentUser.name }}</span>
                  <IconSvg
                    icon="arrow_down"
                    class="w-4 h-4 hs-dropdown-open:rotate-180"
                  />
                </template>
                <div class="accountInfo">
                  <div class="mb-3">
                    <h5 class="text-base font-bold">{{
                      $t('nav.current_user')
                    }}</h5>
                  </div>
                  <div class="flex items-center justify-between gap-7 mb-3.5">
                    <div>
                      <h5 class="text-base font-bold text-gray-80">
                        {{ currentUser.name }}
                      </h5>
                      <p class="text-gray-60">{{ currentUser.email }}</p>
                    </div>
                    <button>
                      <IconSvg icon="settings" class="fill-black" />
                    </button>
                  </div>
                  <div class="">
                    <button class="block w-full btn btn-primary btn-sm">
                      {{ $t('nav.switch_role') }}
                    </button>
                  </div>
                  <hr class="my-3" />
                  <div class="">
                    <div class="flex items-center justify-end w-full gap-1">
                      <Link
                        href="/users/sign_out"
                        method="delete"
                        as="button"
                        class="flex items-center gap-1"
                      >
                        <span class="text-sm underline">{{
                          $t('nav.logout')
                        }}</span>
                        <IconSvg icon="logout" class="w-4 h-4" />
                      </Link>
                    </div>
                  </div>
                </div>
              </DropdownMenu>
            </li>
            <li
              class="hs-dropdown [--strategy:fixed] [--adaptive:none] [--auto-close:inside]"
            >
              <DropdownMenu>
                <template #custom-trigger>
                  <!-- TODO: has notifications -->
                  <!-- <IconSvg icon="notice_unsolved" /> -->
                  <IconSvg icon="notice" />
                </template>
                <h5 class="dropdown-item">
                  {{ $t('nav.notification_center') }}
                </h5>
              </DropdownMenu>
            </li>
            <li class="hs-dropdown [--strategy:fixed] [--adaptive:none]">
              <DropdownMenu
                :button-text="$t(`locale_name.${currentLocale}`)"
              >
                <Link
                  :href="`/?locale=${locale}`"
                  v-for="locale in availableLocales"
                  class="dropdown-item"
                  replace
                >
                  {{ $t(`locale_name.${locale}`) }}
                </Link>
              </DropdownMenu>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { usePage } from "@inertiajs/vue3";

const currentUser = computed(() => usePage().props.current_user);
const currentLocale = computed(() => usePage().props.locale);
const availableLocales = computed(() => gon.available_locales);
const menu_lists = computed(() => usePage().props.menu_lists);
</script>
