<template>
  <div class="relative datatable striped">
    <table class="w-full text-sm text-left">
      <thead>
        <tr>
          <th scope="col" class="px-4 py-2">
            <h5 class="flex items-center gap-1">
              {{ $t("cloud_account.connect_way_api.api_id") }}
            </h5>
          </th>
          <th scope="col" class="px-4 py-2">
            <h5 class="flex items-center gap-1">
              {{ $t("cloud_account.connect_way_api.api_key") }}
            </h5>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <template v-if="!!props.connect_credentials.api_id">
            <td>{{ props.connect_credentials.api_id }}</td>
            <td class="flex items-center gap-2">
              <span v-if="showApiKey">{{ props.connect_credentials.api_key }}</span>
              <span v-else>********</span>

              <span class="cursor-pointer text-primary" @click="showApiKey = !showApiKey">{{ showApiKey ? $t('helpers.hide') : $t('helpers.show') }}</span>
            </td>
          </template>
          <template v-else>
            <td colspan="2" class="text-gray-20">尚無資料</td>
          </template>
        </tr>
      </tbody>
    </table>

    <div class="flex items-center">
      <ConfirmModal
        :button-text="
          props.connect_credentials.api_id
            ? $t('helpers.edit', { model: '' })
            : $t('helpers.new', { model: '' })
        "
        :modal-cancel-text="$t('helpers.back')"
        :modal-submit-text="$t('helpers.confirm')"
        :modal-title="$t('cloud_account.connect_way_api.api_info')"
        modalSubmitClass="btn btn-sm btn-primary"
        @submit="modifyCredentials"
        class="px-10 mx-4 my-3 btn btn-sm btn-primary"
      >

          <div class="py-4">
            <label
            for="cloud_account_connect_credentials_api_id"
            class="ml-3 required"
            >{{ $t("cloud_account.connect_way_api.api_id") }}</label
          >
          <input
            type="text"
            class="input"
            id="cloud_account_connect_credentials_api_id"
            v-model="credentials.api_id"
          />
        </div>
        <div class="py-4">
          <label
            for="cloud_account_connect_credentials_api_key"
            class="ml-3 required"
            >{{ $t("cloud_account.connect_way_api.api_key") }}</label
          >
          <input
            type="text"
            class="input"
            id="cloud_account_connect_credentials_api_key"
              v-model="credentials.api_key"
            />
          </div>

      </ConfirmModal>

      <span class="text-gray-60" v-if="props.updated_at">
        {{ $t("cloud_account.connect_way_api.updated_at") }}：
        {{ props.updated_at }}
      </span>
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";
const props = defineProps({
  connect_credentials: Object,
  updated_at: String,
});

const credentials = ref({
  api_id: props.connect_credentials.api_id,
  api_key: props.connect_credentials.api_key,
});

const showApiKey = ref(false);

const emit = defineEmits(["submit"]);

const modifyCredentials = () => {
  emit("submit", credentials.value);
};
</script>
