<template>
  <button
    type="button"
    aria-haspopup="dialog"
    aria-expanded="false"
    aria-controls="alert-modal"
    data-hs-overlay="#alert-modal"
    class="hidden"
  >
  </button>
  <!-- Main modal -->
  <Teleport to="body">
    <div
      id="alert-modal"
      tabindex="-1"
      role="dialog"
      data-hs-overlay-keyboard="false"
      class="hs-overlay hidden overflow-y-auto overflow-x-hidden [--overlay-backdrop:static] size-full fixed top-0 start-0 z-[80] pointer-events-none"
    >
      <div class="h-[calc(100%-3.5rem)] min-h-[calc(100%-3.5rem)] mx-auto w-full flex items-center max-w-md">
        <!-- Modal content -->
        <div class="flex flex-col w-full max-h-full py-3 overflow-hidden bg-white rounded shadow pointer-events-auto">
          <!-- Modal header -->
          <div
            class="flex items-center justify-center gap-5 px-4 py-3 bg-secondary-dark"
          >
            <slot name="title"></slot>
          </div>

          <!-- Modal body -->
          <div class="px-4 py-3 text-gray-80">
            <slot name="content"></slot>
          </div>

          <div class="flex items-center justify-center gap-3 px-4 py-3">
            <button
              @click="handleConfirm"
              type="button"
              class="w-1/3 btn btn-sm btn-primary"
            >
              {{ modalConfirmText || $t("helpers.confirm") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import { router } from "@inertiajs/vue3";
const props = defineProps({
  modalTitle: { type: String },
  modalConfirmText: { type: String },
  open: { type: Boolean, default: false },
  url: { type: String, default: "/" },
});

const modalOpen = ref(props.open);

onMounted(() => {

  watch(modalOpen, (newVal) => {
    if (newVal) {
      HSOverlay.open('#alert-modal');
    } else {
      HSOverlay.close('#alert-modal');
      router.visit(props.url);
    }
  });

  watch(() => props.open, (newVal) => {
    modalOpen.value = newVal;
  });
});

const handleConfirm = () => {
  modalOpen.value = false
};

</script>
