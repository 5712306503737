<template>
  <nav class="pagination" aria-label="Table navigation">
    <ul>
      <li>
        <template v-if="paginate.prevPage">
          <Link as="button" :href="prevPageUrl">
            <IconSvg icon="arrow_left"></IconSvg>
          </Link>
        </template>
        <template v-else>
          <Link as="button" href="#" disabled>
            <IconSvg icon="arrow_left"></IconSvg>
          </Link>
        </template>
      </li>
    </ul>
    <ul>
      <li class="text-gray-80">
        <label
          for="pagination-page"
          class="flex items-center justify-center gap-1 text-sm"
        >
          <span v-html="$t('pagination.page_input_prefix')"></span>
          <form @submit.prevent="formSubmit" class="flex items-center gap-1">
            <input
              type="number"
              min="1"
              :max="props.paginate.totalPages"
              id="pagination-page"
              class="input input-sm"
              v-model="props.paginate.currentPage"
            />
            /{{ props.paginate.totalPages }}
          </form>
          <span v-html="$t('pagination.page_input_suffix')"></span>
        </label>
      </li>
    </ul>

    <ul>
      <li>
        <template v-if="paginate.nextPage">
          <Link as="button" :href="nextPageUrl">
            <IconSvg icon="arrow_right"></IconSvg>
          </Link>
        </template>
        <template v-else>
          <Link as="button" href="#" disabled>
            <IconSvg icon="arrow_right"></IconSvg>
          </Link>
        </template>
      </li>
    </ul>
  </nav>
</template>

<script setup>
import { computed, onMounted, onBeforeUnmount } from "vue";
import { router } from "@inertiajs/vue3";
import { useHelper } from "./helpers";

const props = defineProps({
  paginate: { type: Object, required: true },
  serverUrl: { type: String },
  pageAround: { type: Number, default: 1 },
  ellipsis: {
    type: Object,
    default: {
      label: "...",
      active: false,
      url: null,
    },
  },
});

const helper = useHelper();
const serverUrl = computed(() => props.serverUrl || window.location.pathname);
const pagination = computed(() => {
  const urlParams = new URLSearchParams(helper.urlSearch.value);
  const delta = props.pageAround;

  const range = [];
  for (
    let i = Math.max(2, props.paginate.currentPage - delta);
    i <=
    Math.min(props.paginate.totalPages - 1, props.paginate.currentPage + delta);
    i++
  ) {
    urlParams.set("page", i);
    range.push({
      label: i,
      active: props.paginate.currentPage === i,
      url: `${serverUrl.value}?${urlParams.toString()}`,
    });
  }

  if (props.paginate.currentPage - delta > 2) {
    range.unshift(props.ellipsis);
  }
  if (props.paginate.currentPage + delta < props.paginate.totalPages - 1) {
    range.push(props.ellipsis);
  }

  const isFirstPage = props.paginate.currentPage === 1;
  const isTotalPages = props.paginate.currentPage === props.paginate.totalPages;

  urlParams.delete("page");
  range.unshift({
    label: 1,
    active: isFirstPage,
    url: `${serverUrl.value}?${urlParams.toString()}`,
  });

  if (props.paginate.totalPages > 1) {
    urlParams.set("page", props.paginate.totalPages);
    range.push({
      label: props.paginate.totalPages,
      active: isTotalPages,
      url: `${serverUrl.value}?${urlParams.toString()}`,
    });
  }

  return range;
});

const prevPageUrl = computed(() => {
  if (props.paginate.prevPage === null) return;

  const urlParams = new URLSearchParams(helper.urlSearch.value);
  urlParams.set("page", props.paginate.prevPage);
  if (props.paginate.prevPage === 1) urlParams.delete("page");

  return `${serverUrl.value}?${urlParams.toString()}`;
});

const nextPageUrl = computed(() => {
  if (props.paginate.nextPage === null) return;

  const urlParams = new URLSearchParams(helper.urlSearch.value);
  urlParams.set("page", props.paginate.nextPage);

  return `${serverUrl.value}?${urlParams.toString()}`;
});

const formSubmit = () => {
  const urlParams = new URLSearchParams(helper.urlSearch.value);
  urlParams.set("page", props.paginate.currentPage);
  router.get(`${serverUrl.value}?${urlParams.toString()}`);
}

const onKeyupEvent = (event) => {
  if (
    localStorage.getItem("enableKeyboard") &&
    localStorage.getItem("enableKeyboard") !== "true"
  )
    return;

  if (
    (event.keyCode === 65 || event.keyCode === 37) &&
    props.paginate.prevPage
  ) {
    router.get(prevPageUrl.value);
  } else if (
    (event.keyCode === 70 || event.keyCode === 39) &&
    props.paginate.nextPage
  ) {
    router.get(nextPageUrl.value);
  }
};

onMounted(() => {
  addEventListener("keyup", onKeyupEvent);
});

onBeforeUnmount(() => {
  removeEventListener("keyup", onKeyupEvent);
});
</script>
