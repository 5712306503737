<template>
  <div class="pb-0 card">
    <div class="card-body">
      <div class="flex items-center justify-between">
        <SubNav :sub-nav-list="tabList"></SubNav>
        <h5 class="font-bold">
          {{ $t("activerecord.attributes.product.name") }}：{{ product.name }}
        </h5>
      </div>
    </div>

    <DataTable :resources="pricing_items" onlyList>
      <template #actions>
        <Link
          :href="`/products/${product.id}/contracts/${contract.id}/pricing_items/new`"
          class="btn btn-sm btn-primary"
        >
          <IconSvg icon="new" class="w-4 h-4"></IconSvg>
          {{ $t("helpers.new", { model: $t("activerecord.models.product") }) }}
        </Link>
      </template>
      <template #item="{ resource }">
        <Item :resource="resource" />
      </template>
    </DataTable>
  </div>
</template>
<script setup>
import { usePage } from "@inertiajs/vue3";
import Item from "./_item.vue";

const props = defineProps({
  product: Object,
  contract: Object,
  pricing_items: Array,
  paginate: Object
});

const tabList = usePage().props.parent_menu_lists;
</script>
