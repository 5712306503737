<template>
  <form @submit.prevent="formSubmit">
    <div class="card">
      <div class="card-body">
        <div class="flex flex-col items-start justify-between gap-5 mx-3 mb-5">
          <div class="flex items-center gap-1">
            <label for="user_group_name" class="required">
              {{ $t("activerecord.attributes.user_group.name") }}</label
            >
            <div>
              <input
                type="text"
                class="input"
                id="user_group_name"
                v-model="form.user_group.name"
                :class="{
                  error: !form.user_group.name,
                }"
              />
            </div>
          </div>
          <div class="flex items-center gap-1">
            <label for="description" class="form-label">{{
              $t("activerecord.attributes.user_group.description")
            }}</label>
            <div>
              <input
                type="text"
                class="input"
                id="description"
                v-model="form.user_group.description"
              />
            </div>
          </div>
        </div>

        <hr class="border-gray-40" />

        <TabNav :tab-list="tabList">
          <div id="user-content" role="tabpanel" aria-labelledby="user-tab">
            <TabUser
              @submit="modifyUserGroup"
              :user_group="form.user_group"
              :users="[]"
              :all_users="props.all_users"
              v-if="tabList.find((tab) => tab.id === 'user').enable"
            />
          </div>
          <div
            id="permission-content"
            role="tabpanel"
            aria-labelledby="permission-tab"
          >
            <TabPermission
              v-if="tabList.find((tab) => tab.id === 'permission').enable"
            />
          </div>
          <div
            id="resource-content"
            role="tabpanel"
            aria-labelledby="resource-tab"
          >
            <TabResource
              v-if="tabList.find((tab) => tab.id === 'resource').enable"
            />
          </div>
        </TabNav>
      </div>
      <div class="card-footer">
        <FormAction
          :can-delete="false"
          :can-submit="canSubmit"
          :back-url="'/user_groups'"
          :has-modified="form.isDirty"
          :submit-text="$t('helpers.create')"
          :processing="form.processing"
        />
      </div>
    </div>
  </form>
</template>

<script setup>
import { useForm, usePage, router } from "@inertiajs/vue3";
import { computed, ref, watch, onMounted } from "vue";
import FormAction from "@/components/form_action.vue";
import TabUser from "./tab_user.vue";
import TabPermission from "./tab_permission.vue";
import TabResource from "./tab_resource.vue";

const tabList = [
  {
    id: "user",
    i18n_key: "user_group.sub_nav.user",
    enable: true,
  },
  {
    id: "permission",
    i18n_key: "user_group.sub_nav.permission",
    enable: false,
  },
  {
    id: "resource",
    i18n_key: "user_group.sub_nav.resource",
    enable: false,
  },
];

const props = defineProps({
  user_group: {
    name: String,
    description: String,
  },
  all_users: {
    type: Array,
    required: true,
  },
  errors: Object,
});

const form = useForm({
  user_group: {
    name: "",
    description: "",
    user_ids: [],
  },
});

const canSubmit = computed(() => {
  return true;
});

const formSubmit = () => {
  form.post("/user_groups");
};

const modifyUserGroup = (new_value) => {
  Object.assign(form.user_group, new_value);
};

onMounted(() => {});
</script>
