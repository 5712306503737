<template>
  <form @submit.prevent="formSubmit" class="space-y-6">
    <div>
      <div class="sm:mx-auto sm:w-full sm:max-w-sm">
        <h4>{{ props.email }}</h4>
        <h4>{{ $t("devise.passwords.edit.message") }}</h4>
      </div>
      <div class="relative mt-2">
        <label for="password" class="relative block">
          <IconSvg icon="lock" class="w-4 h-4 with-input" />
          <input
            v-model="form.user.password"
            id="password"
            type="password"
            autocomplete="new-password"
            required
            :placeholder="$t('activerecord.placeholders.user.password')"
            class="input with-icon"
            :class="{
              error: props.errors.password,
            }"
          />
        </label>
        <IconSvg
          icon="showtext"
          class="w-4 h-4 cursor-pointer with-input input-end"
          v-if="isPasswordVisible"
          @click="togglePasswordVisibility"
        />
        <IconSvg
          icon="hidetext"
          class="w-4 h-4 cursor-pointer with-input input-end"
          v-else
          @click="togglePasswordVisibility"
        />
      </div>
      <p class="text-right text-warning" v-if="props.errors.password">
        {{ props.errors.password }}
      </p>
      <div class="relative mt-2">
        <label for="password_confirmation" class="relative block">
          <IconSvg icon="lock" class="w-4 h-4 with-input" />
          <input
            v-model="form.user.password_confirmation"
            id="password_confirmation"
            type="password"
            autocomplete="new-password"
            required
            :placeholder="
              $t('activerecord.placeholders.user.password_confirmation')
            "
            class="input with-icon"
            :class="{
              error: props.errors.password_confirmation,
            }"
          />
        </label>
        <IconSvg
          icon="showtext"
          class="w-4 h-4 cursor-pointer with-input input-end"
          v-if="isPasswordConfirmationVisible"
          @click="togglePasswordConfirmationVisibility"
        />
        <IconSvg
          icon="hidetext"
          class="w-4 h-4 cursor-pointer with-input input-end"
          v-else
          @click="togglePasswordConfirmationVisibility"
        />
      </div>
      <p
        class="text-right text-warning"
        v-if="props.errors.password_confirmation"
      >
        {{ props.errors.password_confirmation }}
      </p>
      <div class="mt-4">
        <button
          :disabled="!canSubmit"
          type="submit"
          class="w-full btn"
          :class="{
            'btn-primary': canSubmit,
          }"
        >
          {{ $t("helpers.confirm") }}
        </button>
      </div>
    </div>
  </form>
</template>

<script setup>
import { useForm } from "@inertiajs/vue3";
import { computed, ref, onMounted, watch } from "vue";

const props = defineProps({
  email: String,
  reset_password_token: String,
  errors: Object,
});

const isPasswordVisible = ref(false);
const isPasswordConfirmationVisible = ref(false);

const togglePasswordVisibility = () => {
  isPasswordVisible.value = !isPasswordVisible.value;
};

const togglePasswordConfirmationVisibility = () => {
  isPasswordConfirmationVisible.value = !isPasswordConfirmationVisible.value;
};

onMounted(() => {
  const passwordInput = document.querySelector("#password");
  const passwordConfirmationInput = document.querySelector(
    "#password_confirmation"
  );

  watch(isPasswordVisible, (newValue) => {
    if (newValue) {
      passwordInput.type = "text";
    } else {
      passwordInput.type = "password";
    }
  });

  watch(isPasswordConfirmationVisible, (newValue) => {
    if (newValue) {
      passwordConfirmationInput.type = "text";
    } else {
      passwordConfirmationInput.type = "password";
    }
  });
});

const form = useForm({
  user: {
    password: "",
    password_confirmation: "",
    reset_password_token: props.reset_password_token,
  },
});

const canSubmit = computed(() => {
  return form.user.password && form.user.password_confirmation;
});

const formSubmit = () => {
  isPasswordVisible.value = false;
  isPasswordConfirmationVisible.value = false;
  form.put("/users/password", {
    preserveState: true,
  });
};
</script>
