<template>
  <form @submit.prevent="formSubmit">
    <div class="card">
      <div class="card-body">
        <TabNav :tab-list="tabList" class="py-0">
          <TabInfo
            @submit="modifyCloudAccount"
            :cloud_account="form.cloud_account"
            :options_for_select="props.options_for_select"
          />

          <TabConnectWay
            @submit="modifyCloudAccount"
            :cloud_account_connector="
              form.cloud_account.cloud_account_connector_attributes
            "
          />

          <TabContract :cloud_account="form.cloud_account" />

          <TabComment
            :cloud_account_id="props.cloud_account.id"
            :comments="props.comments"
          />
        </TabNav>
      </div>
      <div class="card-footer">
        <FormAction
          :can-delete="false"
          :can-submit="canSubmit"
          :back-url="'/cloud_accounts'"
          :has-modified="form.isDirty"
          :submit-text="$t('helpers.save')"
          :processing="form.processing"
        />
      </div>
    </div>
  </form>
</template>

<script setup>
import { useForm } from "@inertiajs/vue3";
import { computed, ref, watch, onMounted } from "vue";
import FormAction from "@/components/form_action.vue";
import TabInfo from "./tab_info.vue";
import TabConnectWay from "./tab_connect_way.vue";
import TabContract from "./tab_contract.vue";
import TabComment from "./tab_comment.vue";

const tabList = [
  {
    id: "info",
    i18n_key: "cloud_account.sub_nav.info",
    enable: true,
  },
  {
    id: "connect_way",
    i18n_key: "cloud_account.sub_nav.connect_way",
    enable: true,
  },
  {
    id: "contract",
    i18n_key: "cloud_account.sub_nav.contract",
    enable: true,
  },
  {
    id: "comment",
    i18n_key: "cloud_account.sub_nav.comment",
    enable: true,
  },
];

const props = defineProps({
  cloud_account: Object,
  cloud_account_connector: Object,
  comments: Array,
  options_for_select: Object,
  errors: Object,
});

const form = useForm({
  cloud_account: {
    ...Object.fromEntries(
      Object.entries(props.cloud_account).filter(([key]) => key !== "id")
    ),
    cloud_account_connector_attributes: {
      id: props.cloud_account_connector.id,
      connect_way: props.cloud_account_connector.connect_way,
      credentials: props.cloud_account_connector.credentials,
    },
  },
});

const canSubmit = computed(() => {
  return (
    !!form.cloud_account.account_id &&
    !!form.cloud_account.start_at &&
    !!form.cloud_account.status &&
    !!form.cloud_account.product_id
  );
});
const formSubmit = () => {
  form.put(`/cloud_accounts/${props.cloud_account.id}`);
};

const modifyCloudAccount = (new_value) => {
  Object.assign(form.cloud_account, new_value);
};
</script>
