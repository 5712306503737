<template>
  <div v-if="message && visible" class="alert alert-danger" role="alert">
    <div class="alert-header">
      <span class="alert-title-group">
        <IconSvg icon="status_no" />
        <span class="alert-title">{{ title }}</span>
      </span>
      <IconSvg icon="close" class="cursor-pointer" @click="hideAlert" />
    </div>
    <div class="alert-body" v-if="content" v-html="content"></div>
  </div>
</template>
<script setup>
import { computed, ref } from "vue";
import { usePage } from "@inertiajs/vue3";

const props = defineProps({
  message: String,
  formMessage: Object,
  full: Boolean,
});
const flash = computed(() => usePage().props.flash);

const message = computed(() => {
  if (props.message) return props.message;
  if (flash.value?.alert) return flash.value.alert;
  if (props.formMessage) return Object.values(props.formMessage).join(" ");

  return undefined;
});

const title = computed(() => message.value.split("<br>")[0]);
const content = computed(() => message.value.split("<br>")[1]);

const visible = ref(true);
const hideAlert = () => {
  visible.value = false;
};
</script>
