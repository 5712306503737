<template>
  <form @submit.prevent="formSubmit">
    <div class="card">
      <div class="card-body">
        <div class="flex items-center justify-between">
          <SubNav :sub-nav-list="tabList"></SubNav>
          <h5 class="font-bold">
            {{ $t("activerecord.attributes.product.name") }}：{{
              props.product.name
            }}
          </h5>
        </div>
        <Info
          @submit="modifyContract"
          :contract="form.contract"
          :product="props.product"
          :options_for_select="props.options_for_select"
        />
      </div>

      <div class="card-footer">
        <FormAction
          :can-delete="false"
          :can-submit="canSubmit"
          :back-url="`/products/${product.id}/contracts`"
          :has-modified="form.isDirty"
          :submit-text="$t('helpers.create')"
          :processing="form.processing"
        />
      </div>
    </div>
  </form>
</template>
<script setup>
import { usePage, useForm } from "@inertiajs/vue3";
import { computed } from "vue";
import FormAction from "@/components/form_action.vue";
import Info from "./info.vue";
const props = defineProps({
  product: Object,
  options_for_select: Object,
  contract: Object,
  errors: Object,
});

const tabList = computed(() => usePage().props.sub_menu_lists);

const form = useForm({
  contract: props.contract,
});

const validAdditionalInfo = computed(() => {
  return form.contract.category === "additional"
    ? !!form.contract.additional_info.main_submission_no &&
        !!form.contract.additional_info.main_approval_no
    : true;
});

const canSubmit = computed(() => {
  return (
    !!form.contract.name &&
    !!form.contract.start_at &&
    !!form.contract.end_at &&
    !!form.contract.category &&
    !!form.contract.approval_no &&
    !!form.contract.submission_no &&
    !!form.contract.contact.email &&
    !!form.contract.contact.name &&
    !!form.contract.bill_info_day &&
    validAdditionalInfo.value
  );
});

const formSubmit = () => {
  form.post(`/products/${props.product.id}/contracts`);
};

const modifyContract = (new_value) => {
  Object.assign(form.contract, new_value);

  console.log(form.contract);
};
</script>
