<template>
  <form @submit.prevent="formSubmit">
    <div class="card">
      <div class="card-body">
        <SubNav :sub-nav-list="tabList"></SubNav>

        <Info
          @submit="modifyProduct"
          :product="form.product"
          :options_for_select="props.options_for_select"
        />
      </div>
      <div class="card-footer">
        <FormAction
          :can-submit="canSubmit"
          :delete-url="`/products/${props.product.id}`"
          :has-modified="form.isDirty"
          :delete-target="props.product.name"
          :delete-from="$t('nav.product')"
          :back-url="'/products'"
          :submit-text="$t('helpers.save')"
          :processing="form.processing"
        />
      </div>
    </div>
  </form>
</template>

<script setup>
import { useForm, usePage, router } from "@inertiajs/vue3";
import { computed, ref, watch, onMounted } from "vue";
import FormAction from "@/components/form_action.vue";
import Info from "./info.vue";

const props = defineProps({
  product: Object,
  options_for_select: Object,
  errors: Object,
});

const tabList = computed(() => usePage().props.sub_menu_lists);

const form = useForm({
  product: props.product,
});

const validPaymentTerm = computed(() => {
  return form.product.payment_term === "other"
    ? form.product.payment_term_other
    : !!form.product.payment_term;
});
const validExchangeRate = computed(() => {
  return form.product.exchange_rate === "fix_rate"
    ? Number(form.product.exchange_rate_fix) > 0
    : !!form.product.exchange_rate;
});

const canSubmit = computed(() => {
  return (
    !!form.product.summary_name &&
    !!form.product.name &&
    !!form.product.supplier &&
    !!form.product.owner_id &&
    !!form.product.primary_contact.email &&
    !!form.product.billing_contact.email &&
    !!form.product.primary_contact.name &&
    !!form.product.billing_contact.name &&
    !!form.product.origin_currency &&
    !!form.product.trade_currency &&
    validPaymentTerm.value &&
    validExchangeRate.value
  );
});

const formSubmit = () => {
  form.put(`/products/${props.product.id}`);
};

const modifyProduct = (new_value) => {
  Object.assign(form.product, new_value);
};
</script>
