<template>
  <div class="space-5">
    <dl class="grid grid-cols-8 gap-5 my-5">
      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="supplier_contract_status">{{
          $t("activerecord.attributes.supplier/contract.status")
        }}</label>
      </dt>
      <dd class="flex items-center justify-between col-span-7 gap-5 w-max">
        <div>
          <multiselect
            v-model="form.contract.status"
            :options="props.options_for_select.statuses"
            :show-labels="false"
            :searchable="false"
            :allow-empty="false"
            :max-height="120"
          >
            <template #singleLabel="props">
              {{ $t(`supplier/contract.status.${props.option}`) }}
            </template>
            <template #option="props">
              {{ $t(`supplier/contract.status.${props.option}`) }}
            </template>
            <template #caret>
              <IconSvg
                icon="arrow_down"
                class="absolute w-4 h-4 -translate-y-1/2 top-1/2 right-2 fill-gray-80"
              ></IconSvg>
            </template>
          </multiselect>
        </div>
      </dd>

      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="supplier_contract_name">{{
          $t("activerecord.attributes.supplier/contract.name")
        }}</label>
      </dt>
      <dd class="flex items-center justify-between col-span-7 gap-5 w-max">
        <input
          type="text"
          class="input"
          id="supplier_contract_name"
          v-model="form.contract.name"
          :class="{
            error: !form.contract.name,
          }"
        />
      </dd>

      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="supplier_contract_user_id">{{
          $t("activerecord.attributes.supplier/contract.user")
        }}</label>
      </dt>
      <dd class="flex items-center justify-between col-span-7 gap-5 w-max">
        <div class="flex items-center">
          <multiselect
            v-model="selectedUser"
            :options="props.options_for_select.users"
            :show-labels="false"
            :searchable="false"
            :allow-empty="false"
            :max-height="120"
            class="min-w-[300px]"
            :placeholder="$t('helpers.select')"
            @select="form.contract.user_id = $event.id"
          >
            <template #singleLabel="props">
              {{ `${props.option.email} (${props.option.name})` }}
            </template>
            <template #option="props">
              {{ `${props.option.email} (${props.option.name})` }}
            </template>
            <template #caret>
              <IconSvg
                icon="arrow_down"
                class="absolute w-4 h-4 -translate-y-1/2 top-1/2 right-2 fill-gray-80"
              ></IconSvg>
            </template>
          </multiselect>
        </div>
      </dd>

      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="supplier_contract_category">{{
          $t("activerecord.attributes.supplier/contract.category")
        }}</label>
      </dt>
      <dd class="flex items-center justify-between col-span-7 gap-5 w-max">
        <div>
          <multiselect
            v-model="form.contract.category"
            :options="props.options_for_select.categories"
            :show-labels="false"
            :searchable="false"
            :allow-empty="false"
            :max-height="120"
            class="min-w-[100px]"
          >
            <template #singleLabel="props">
              {{ $t(`supplier/contract.category.${props.option}`) }}
            </template>
            <template #option="props">
              {{ $t(`supplier/contract.category.${props.option}`) }}
            </template>
            <template #caret>
              <IconSvg
                icon="arrow_down"
                class="absolute w-4 h-4 -translate-y-1/2 top-1/2 right-2 fill-gray-80"
              ></IconSvg>
            </template>
          </multiselect>
        </div>

        <div class="flex flex-col gap-5">
          <div class="flex items-center gap-3">
            <label class="ml-3 required" for="supplier_contract_approval_no">
              {{ $t("activerecord.attributes.supplier/contract.approval_no") }}
            </label>

            <label class="ml-3" for="supplier_contract_approval_no">
              {{ $t(`supplier/contract.category.${form.contract.category}`) }}
            </label>
            <span>
              <input
                type="text"
                class="input"
                id="supplier_contract_approval_no"
                v-model="form.contract.approval_no"
                :class="{
                  error: !form.contract.approval_no,
                }"
              />
            </span>
            <template v-if="form.contract.category === 'additional'">
              <label
                class="ml-3"
                for="supplier_contract_additional_main_approval_no"
              >
                {{ $t("supplier/contract.category.main") }}
              </label>
              <span>
                <input
                  type="text"
                  class="input"
                  id="supplier_contract_additional_main_approval_no"
                  v-model="form.contract.additional_info.main_approval_no"
                  :class="{
                    error: !form.contract.additional_info.main_approval_no,
                  }"
                />
              </span>
            </template>
          </div>

          <div class="flex items-center gap-3">
            <label class="ml-3 required" for="supplier_contract_submission_no">
              {{
                $t("activerecord.attributes.supplier/contract.submission_no")
              }}
            </label>

            <label class="ml-3" for="supplier_contract_submission_no">
              {{ $t(`supplier/contract.category.${form.contract.category}`) }}
            </label>
            <span>
              <input
                type="text"
                class="input"
                id="supplier_contract_submission_no"
                v-model="form.contract.submission_no"
                :class="{
                  error: !form.contract.submission_no,
                }"
              />
            </span>

            <template v-if="form.contract.category === 'additional'">
              <label
                class="ml-3"
                for="supplier_contract_additional_submission_no"
              >
                {{ $t("supplier/contract.category.main") }}
              </label>
              <span>
                <input
                  type="text"
                  class="input"
                  id="supplier_contract_additional_submission_no"
                  v-model="form.contract.additional_info.main_submission_no"
                  :class="{
                    error: !form.contract.additional_info.main_submission_no,
                  }"
                />
              </span>
            </template>
          </div>
        </div>
      </dd>

      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="supplier_contract_start_at">{{
          $t("activerecord.attributes.supplier/contract.start_at")
        }}</label>
      </dt>
      <dd class="flex items-center justify-between col-span-7 gap-3 w-max">
        <DatePicker
          :current_date="form.contract.start_at"
          @submit="modifyStartAt"
        />
        <div class="flex items-center gap-2">
          <label class="ml-3 required" for="supplier_contract_end_at">{{
            $t("activerecord.attributes.supplier/contract.end_at")
          }}</label>

          <DatePicker
            :current_date="form.contract.end_at"
            @submit="modifyEndAt"
          />
        </div>
      </dd>
    </dl>
    <hr class="border-gray-40" />

    <dl class="grid grid-cols-8 gap-5 my-5">
      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="supplier_contract_contact">{{
          $t("activerecord.attributes.supplier/contract.contact")
        }}</label>
      </dt>
      <dd class="flex items-center justify-between col-span-7 gap-5 w-max">
        <div class="flex items-center gap-1">
          <label for="supplier_contract_same_contact" class="custom-checkbox">
            <input
              type="checkbox"
              id="supplier_contract_same_contact"
              v-model="sameContact"
            />
            {{ $t("supplier/contract.contact.same_as_product") }}
            <span class="checkmark"></span>
          </label>
        </div>

        <div class="flex items-center gap-1">
          <label class="ml-3 required" for="supplier_contract_contact_email">{{
            $t("activerecord.attributes.supplier/contract.contact_email")
          }}</label>

          <span>
            <input
              class="input"
              id="supplier_contract_contact_email"
              type="email"
              v-model="form.contract.contact.email"
              :disabled="sameContact"
            />
          </span>
        </div>

        <div class="flex items-center gap-1">
          <label class="ml-3 required" for="supplier_contract_contact_name">{{
            $t("activerecord.attributes.supplier/contract.contact_name")
          }}</label>

          <span>
            <input
              class="input"
              id="supplier_contract_contact_name"
              type="text"
              v-model="form.contract.contact.name"
              :disabled="sameContact"
            />
          </span>
        </div>
      </dd>

      <dt class="flex items-center col-span-1">
        <label class="ml-3 required" for="supplier_contract_bill_info_day">{{
          $t("activerecord.attributes.supplier/contract.bill_info_day")
        }}</label>
      </dt>
      <dd class="flex items-center col-span-7 gap-5">
        <div class="flex items-center gap-2">
          <span class="break-keep">每月</span>
          <multiselect
            v-model="form.contract.bill_info_day"
            :options="props.options_for_select.bill_info_days"
            :show-labels="false"
            :searchable="false"
            :allow-empty="false"
            :max-height="120"
            :placeholder="$t('helpers.select')"
            class="min-w-[100px]"
          >
            <template #caret>
              <IconSvg
                icon="arrow_down"
                class="absolute w-4 h-4 -translate-y-1/2 top-1/2 right-2 fill-gray-80"
              ></IconSvg>
            </template>
          </multiselect>
          <span>號</span>
        </div>
        <div class="flex grid items-center w-full grid-cols-11 gap-3">
          <label
            class="col-span-1 ml-3"
            for="supplier_contract_payment_term"
          >
            {{ $t("activerecord.attributes.supplier/contract.payment_term") }}
          </label>
          <span class="col-span-10">
            <input
              class="input"
              id="supplier_contract_payment_term"
              v-model="form.contract.payment_term"
            />
          </span>
        </div>
      </dd>
      <dt class="flex items-center col-span-1">
        <label class="ml-3" for="supplier_contract_overdue_term">{{
          $t("activerecord.attributes.supplier/contract.overdue_term")
        }}</label>
      </dt>
      <dd class="flex items-center justify-between w-full col-span-7 gap-5">
        <input
          class="input"
          id="supplier_contract_overdue_term"
          v-model="form.contract.overdue_term"
        />
      </dd>

      <dt class="flex items-center col-span-1">
        <label class="ml-3" for="supplier_contract_failure_term">{{
          $t("activerecord.attributes.supplier/contract.failure_term")
        }}</label>
      </dt>
      <dd class="flex items-center justify-between w-full col-span-7 gap-5">
        <input
          class="input"
          id="supplier_contract_failure_term"
          v-model="form.contract.failure_term"
        />
      </dd>

      <dt class="flex items-center col-span-1">
        <label class="ml-3" for="supplier_contract_comment">{{
          $t("activerecord.attributes.supplier/contract.comment")
        }}</label>
      </dt>
      <dd class="flex items-center justify-between w-full col-span-7 gap-5">
        <input
          class="input"
          id="supplier_contract_comment"
          v-model="form.contract.comment"
        />
      </dd>
    </dl>
  </div>
</template>
<script setup>
import { useForm, router } from "@inertiajs/vue3";
import { ref, watch, onMounted } from "vue";

const props = defineProps({
  contract: {
    type: Object,
    required: true,
  },
  product: {
    type: Object,
    required: true,
  },
  options_for_select: Object,
});
const form = useForm({
  contract: {
    status: props.contract.status,
    category: props.contract.category,
    name: props.contract.name,
    start_at: props.contract.start_at,
    end_at: props.contract.end_at,
    payment_term: props.contract.payment_term,
    overdue_term: props.contract.overdue_term,
    failure_term: props.contract.failure_term,
    bill_info_day: props.contract.bill_info_day,
    contact: {
      email: props.contract.contact.email,
      name: props.contract.contact.name,
    },
    approval_no: props.contract.approval_no,
    submission_no: props.contract.submission_no,
    additional_info: {
      main_approval_no: props.contract.additional_info.main_approval_no,
      main_submission_no: props.contract.additional_info.main_submission_no,
    },
  },
});

const emit = defineEmits(["submit"]);
const selectedUser = ref(
  props.options_for_select.users.find(
    (user) => user.id === props.contract.user_id
  )
);

const sameContact = ref(!props.contract.id);

const modifyStartAt = (value) => {
  form.contract.start_at = value;
};

const modifyEndAt = (value) => {
  form.contract.end_at = value;
};

onMounted(() => {
  watch(sameContact, () => {
    if (sameContact.value) {
      form.contract.contact.email = props.product.primary_contact.email;
      form.contract.contact.name = props.product.primary_contact.name;
    }
  });
  watch(form.contract, () => {
    emit("submit", form.contract);
  });
});
</script>
