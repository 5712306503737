<template>
  <form @submit.prevent="formSubmit" class="space-y-6">
    <div>
      <div class="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 class="mb-4 text-2xl font-bold leading-9 tracking-tight text-left">
          {{ $t("devise.passwords.new.forgot_your_password") }}
          <Link class="text-sm btn-link" href="/users/sign_in" preserve-scroll>
            {{ $t("devise.passwords.new.back_to_sign_in") }}
          </Link>
        </h2>
        <p class="mb-4" v-html="$t('devise.passwords.new.message')"></p>
      </div>

      <div class="mt-2">
        <label for="email" class="relative block">
          <IconSvg icon="account" class="w-4 h-4 with-input" />
          <input
            v-model="form.user.email"
            id="email"
            type="email"
            autocomplete="email"
            required
            placeholder="Email"
            class="input with-icon"
          />
        </label>
      </div>

      <div class="mt-4">
        <button
          :disabled="!canSubmit || !emailValidate(form.user.email)"
          type="submit"
          class="w-full btn btn-primary"
        >
          {{ $t("devise.passwords.new.send_me_reset_password_instructions") }}
          <span v-if="countDown > 0">{{
            $t("devise.passwords.new.throttle_count_down", {
              count_down: countDown,
            })
          }}</span>
        </button>
      </div>
    </div>
  </form>
</template>

<script setup>
import { useForm } from "@inertiajs/vue3";
import { computed, watch, ref, onMounted } from "vue";

const props = defineProps({
  email: String,
  count_down: Number,
});

const form = useForm({
  user: {
    email: props.email,
  },
});

const countDown = ref(props.count_down);
onMounted(() => {
  watch(
    () => countDown.value,
    (newValue) => {
      if (newValue > 0) {
        setTimeout(() => {
          countDown.value--;
        }, 1000);
      }
    }
  );
});

const canSubmit = computed(() => {
  return !!form.user.email && countDown.value === 0;
});

const formSubmit = () => {
  form.post("/users/password", {
    preserveState: true,
    onSuccess: (page) => {
      countDown.value = page.props.count_down;
    },
  });
};
</script>
