<template>
  <div class="card">
    <DataTable :resources="cloud_accounts" :paginate="paginate">
      <template #actions>
        <div class="flex items-center gap-5">
          <Link href="/cloud_accounts/new" class="btn btn-sm btn-primary">
            <IconSvg icon="new" class="w-4 h-4"></IconSvg>
            {{
              $t("helpers.new", {
                model: $t("activerecord.models.cloud_account"),
              })
            }}
          </Link>
          <Import :authenticity_token="props.authenticity_token" />
          <ListSetting
            :list_setting="props.list_setting"
            :authenticity_token="props.authenticity_token"
          />
        </div>
      </template>
      <template #thead>
        <DataTableHead id="checkbox" :disableOrder="true" class="w-5">
          <label for="cloud-account-select-all" class="custom-checkbox">
            <input
              type="checkbox"
              id="cloud-account-select-all"
              v-model="selectAll"
            />
            <span class="checkmark checkmark-sm"></span>
          </label>
        </DataTableHead>

        <DataTableHead
          v-for="thead in props.list_setting"
          :id="thead"
          :text="$t(`cloud_account.index.${thead}`)"
          :defaultOrderColumn="thead === 'account_id'"
          :disableOrder="thead != 'account_id'"
        >
        </DataTableHead>
      </template>
      <template #tbody-tr="{ resource }">
        <td>
          <span class="flex items-center gap-1">
            <label
              :for="`cloud-account-select-${resource.id}`"
              class="custom-checkbox"
            >
              <input
                type="checkbox"
                :id="`cloud-account-select-${resource.id}`"
                v-model="selected_ids"
                :value="resource.id"
              />
              <span class="checkmark checkmark-sm"></span>
            </label>
          </span>
        </td>
        <td v-for="cloud_account_column in props.list_setting">
          <template v-if="cloud_account_column === 'account_id'">
            <Link
              class="text-gray-60"
              :href="`/cloud_accounts/${resource.id}/edit`"
              >{{ resource.account_id }}</Link
            >
          </template>
          <template v-else>
            {{ resource[cloud_account_column] }}
          </template>
        </td>
      </template>
      <template #footer-actions>
        <DownloadList
          :download_ids="selected_ids"
          :authenticity_token="props.authenticity_token"
        />
      </template>
    </DataTable>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import Import from "./import.vue";
import ListSetting from "./list_setting.vue";
import DownloadList from "./download_list.vue";

const props = defineProps({
  cloud_accounts: {
    type: Array,
    required: true,
  },
  list_setting: {
    type: Array,
    required: true,
  },
  paginate: {
    type: Object,
    required: true,
  },
  authenticity_token: {
    type: String,
    required: true,
  },
});

const selected_ids = ref([]);

const selectAll = ref(false);

onMounted(() => {
  if (selected_ids.value.length === props.cloud_accounts.length) {
    selectAll.value = true;
  } else if (selected_ids.value.length > 0) {
    selectAll.value = false;
  } else {
    selectAll.value = false;
  }

  watch(selectAll, (newVal) => {
    if (newVal) {
      selected_ids.value = props.cloud_accounts.map(
        (cloud_account) => cloud_account.id
      );
    } else {
      selected_ids.value = [];
    }
  });

  watch(
    () => selected_ids.value,
    (newVal) => {
      const checkedIds = newVal;
      const element = document.querySelector("#cloud-account-select-all");

      if (checkedIds.length === 0) {
        element.indeterminate = false;
        selectAll.value = false;
      } else if (checkedIds.length === props.cloud_accounts.length) {
        element.indeterminate = false;
        selectAll.value = true;
      } else {
        element.indeterminate = true;
      }
    }
  );
});
</script>
